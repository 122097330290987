import { Component, OnInit } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import {first, take} from 'rxjs/operators';
import { AuthenticationService } from '../../services/authentication.service';

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.css']
})
export class LoginComponent implements OnInit {
  loginForm: FormGroup;
  loading = false;
  submitted = false;
  returnUrl: string;
  size: 'compact';
  theme: 'light';
  type: 'image';
  lang: 'es';
  constructor(
    private formBuilder: FormBuilder,
    private route: ActivatedRoute,
    private router: Router,
    private authenticationService: AuthenticationService,
  ) {
    if (this.authenticationService.currentUserValue) {
      this.router.navigate(['/']);
    }
  }

  get f() { return this.loginForm.controls; }

  ngOnInit(): void {
    this.loginForm = this.formBuilder.group({
      email: ['', [Validators.required, Validators.email]],
      password: ['', Validators.required],
      recaptcha: ['', Validators.required]
    });

    // get return url from route parameters or default to '/'
    this.returnUrl = this.route.snapshot.queryParams['returnUrl'] || '/login';
  }

  goToRegisterPage = () => {
    this.router.navigate(['/registro']);
  }

  onSubmit(): void {
    this.submitted = true;
    console.log('estamos en el manejador del login');
    // stop here if form is invalid
    if (this.loginForm.invalid) {
      console.log('es invalido');
      return;
    }

    console.log(this.f.email.value);
    console.log(this.f.password.value);
    console.log(this.f.recaptcha.value);

    this.loading = true;
    this.authenticationService.login(this.f.email.value, this.f.password.value)
      .pipe(take(3))
      .subscribe(data => {
          console.log(data);
          this.loading = false;
        },
        error => {
          console.log(error);
          this.loading = false;
        });
  }

  handleSuccess(event): void {
    console.log(event);
  }

  handleLoad(): void {
    console.log( 'Esta cargando' );
  }

  handleExpire(): void {
    console.log('captcha ha expirado');
  }

  handleReset(): void {
    console.log('captcha ha sido reseteado');
  }

  siteKey(): any {
    return '6LdHqJ4UAAAAAM_qCsBbBmXYecwD2Vuo9klZIPEB';
  }
}
