import { Component, OnInit } from '@angular/core';
import { User } from '../../models/user';
import { AuthenticationService } from '../../services/authentication.service';
import { ChannelsService } from '../../services/channels.service';
import { MyChannelService } from '../../services/mychannel.service';
import {take} from 'rxjs/operators';
import {Video} from '../../models/video';

@Component({
  selector: 'app-canal',
  templateUrl: './canal.component.html',
  styleUrls: ['./canal.component.css']
})
export class CanalComponent implements OnInit {
  usuario: User;
  loggedIn: boolean;
  public videos: [] = [];
  constructor(
    public authenticationService: AuthenticationService,
    public canalService: ChannelsService,
    public micanalService: MyChannelService
  ) { }

  ngOnInit(): void {
    this.usuario = this.authenticationService.currentUserValue;
    this.loggedIn = this.authenticationService.loggedIn;
    console.log(this.usuario);
    console.log(this.loggedIn);
    this.getAllVideosByUser(this.usuario.email);
  }

  getAllVideosByUser(email): void {
    this.micanalService.getvideos(email)
      .pipe(take(3))
      .subscribe(data => {
          console.log(data);
          this.videos = data;
        },
        error => {
          console.log(error);
        });
  }
}
