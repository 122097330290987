import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-login-carousel',
  templateUrl: './login-carousel.component.html',
  styleUrls: ['./login-carousel.component.css']
})
export class LoginCarouselComponent implements OnInit {
  slidesStore = [
    {
      id: 'primera',
      title: 'Download videos effortlessly',
      subtitle: {
        primer: 'when an unknown printer took a galley of type and scrambled',
        segun: ' it to make a type specimen book. It has survived not ',
        tercer: 'only five centuries'
      },
      src: 'assets/img/login.png',
      alt: 'Download videos effortlessly'
    },
    {
      id: 'segunda',
      title: 'create GIFs',
      subtitle: {
        primer: 'when an unknown printer took a galley of type and scrambled',
        segun: ' it to make a type specimen book. It has survived not ',
        tercer: 'only five centuries'
      },
      src: 'assets/img/login.png',
      alt: 'Download videos effortlessly'
    },
    {
      id: 'tercera',
      title: '​Watch videos offline',
      subtitle: {
        primer: 'when an unknown printer took a galley of type and scrambled',
        segun: ' it to make a type specimen book. It has survived not ',
        tercer: 'only five centuries'
      },
      src: 'assets/img/login.png',
      alt: 'Download videos effortlessly'
    }
  ];

  customOptions: any = {
    margin: 0,
    responsiveClass: true,
    nav: true,
    dots: true,
    autoplay: true,
    navText: ['<i class="fa fa-chevron-left"></i>', '<i class="fa fa-chevron-right"></i>'],
    loop: true,
    mouseDrag: false,
    touchDrag: false,
    slideBy: 1,
    pullDrag: false,
    navSpeed: 700,
    responsive: {
      0: {
        items: 1,
        nav: false
      },
      400: {
        items: 1,
        nav: false,
      },
      600: {
        items: 1,
        nav: true,
      },
      700: {
        items: 1,
        nav: true,
      },
      800: {
        items: 1,
        nav: true,
      },
      1000: {
        items: 1,
        nav: true
      }
    }
  };
  constructor() { }

  ngOnInit(): void {
  }

}
