<div class="top-category section-padding mb-4">
  <div class="row">
    <div class="col-md-12">
      <div class="main-title">
        <div class="btn-group float-right right-action">
          <a href="#" class="right-action-link text-gray" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
            <i class="fa fa-ellipsis-h" aria-hidden="true"></i>
          </a>
          <div class="dropdown-menu dropdown-menu-right">
            <a class="dropdown-item" href="#"><i class="fas fa-fw fa-star"></i> &nbsp; Top Rated</a>
            <a class="dropdown-item" href="#"><i class="fas fa-fw fa-signal"></i> &nbsp; Viewed</a>
            <a class="dropdown-item" href="#"><i class="fas fa-fw fa-times-circle"></i> &nbsp; Close</a>
          </div>
        </div>
        <h6>Selecciona por Categoría</h6>
      </div>
    </div>
    <div class="col-md-12">
      <div class="owl-carousel owl-carousel-category owl-loaded owl-drag">
        <div class="owl-stage-outer">
            <owl-carousel-o class="owl-stage" [options]="customOptions" style="transform: translate3d(-2478px, 0px, 0px); transition: all 1s ease 0s; width: 4248px;">
              <ng-container *ngFor="let slide of slideStore">
                <ng-template carouselSlide>
                  <div class="owl-item cloned" style="width: 177px;">
                    <div class="item">
                      <div class="category-item">
                        <a routerLink="/categoria">
                          <img class="img-fluid" [src]="slide.src" alt="">
                          <h6>{{slide.categoryname}}</h6>
                          <p>{{slide.categoryviews}} views</p>
                        </a>
                      </div>
                    </div>
                  </div>
                </ng-template>
              </ng-container>
            </owl-carousel-o>
          </div>
        </div>
    </div>
  </div>
</div>
<hr>
