<app-nav [loggedIn]="loggedIn" [user]="usuario"></app-nav>
<div id="wrapper">
  <app-sidebar [loggedIn]="loggedIn" [user]="usuario"></app-sidebar>
  <div class="single-channel-page" id="content-wrapper">
    <div class="single-channel-image">
      <img class="img-fluid" alt="" src="assets/img/channel-banner.png">
      <div class="channel-profile">
        <img class="channel-profile-img" alt="" src="assets/img/s2.png">
        <div class="social hidden-xs">
          Social &nbsp;
          <a class="fb" href="#">Facebook</a>
          <a class="tw" href="#">Twitter</a>
          <a class="gp" href="#">Google</a>
        </div>
      </div>
    </div>
    <div class="single-channel-nav">
      <nav class="navbar navbar-expand-lg navbar-light">
        <a class="channel-brand" href="#">Osahan Channel <span title="" data-placement="top" data-toggle="tooltip" data-original-title="Verified"><i class="fas fa-check-circle text-success"></i></span></a>
        <button class="navbar-toggler" type="button" data-toggle="collapse" data-target="#navbarSupportedContent" aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation">
          <span class="navbar-toggler-icon"></span>
        </button>
        <div class="collapse navbar-collapse" id="navbarSupportedContent">
          <ul class="navbar-nav mr-auto">
            <li class="nav-item active">
              <a class="nav-link" href="#">Videos <span class="sr-only">(current)</span></a>
            </li>
            <li class="nav-item">
              <a class="nav-link" href="#">Playlist</a>
            </li>
            <li class="nav-item">
              <a class="nav-link" href="#">Channels</a>
            </li>
            <li class="nav-item">
              <a class="nav-link" href="#">Discussion</a>
            </li>
            <li class="nav-item">
              <a class="nav-link" href="#">About</a>
            </li>
            <li class="nav-item dropdown">
              <a class="nav-link dropdown-toggle" href="#" id="navbarDropdown" role="button" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                Donate
              </a>
              <div class="dropdown-menu" aria-labelledby="navbarDropdown">
                <a class="dropdown-item" href="#">Action</a>
                <a class="dropdown-item" href="#">Another action</a>
                <div class="dropdown-divider"></div>
                <a class="dropdown-item" href="#">Something else here</a>
              </div>
            </li>
          </ul>
          <form class="form-inline my-2 my-lg-0">
            <input class="form-control form-control-sm mr-sm-1" type="search" placeholder="Search" aria-label="Search">
            <button class="btn btn-outline-success btn-sm my-2 my-sm-0" type="submit">
              <i class="fas fa-search"></i>
            </button> &nbsp;&nbsp;&nbsp;
            <button class="btn btn-outline-danger btn-sm" type="button">Subscribe <strong>1.4M</strong></button>
          </form>
        </div>
      </nav>
    </div>
    <div class="container-fluid">
      <div class="video-block section-padding">
        <div class="row">
          <div class="col-md-12">
            <div class="main-title">
              <div class="btn-group float-right right-action">
                <a href="#" class="right-action-link text-gray" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                  Ordenar por <i class="fa fa-caret-down" aria-hidden="true"></i>
                </a>
                <div class="dropdown-menu dropdown-menu-right">
                  <a class="dropdown-item" href="#"><i class="fas fa-fw fa-star"></i> &nbsp; Mas valorados</a>
                  <a class="dropdown-item" href="#"><i class="fas fa-fw fa-signal"></i> &nbsp; Mas vistos</a>
                  <a class="dropdown-item" href="#"><i class="fas fa-fw fa-times-circle"></i> &nbsp; Eliminados</a>
                </div>
              </div>
              <h6>Videos</h6>
            </div>
          </div>
          <div *ngFor="let video of videos" class="col-xl-3 col-sm-6 mb-3">
            <div class="video-card">
              <div class="video-card-image">
                <a class="play-icon" routerLink="/watch/{{video.videoId}}"><i class="fas fa-play-circle"></i></a>
                <a routerLink="/watch/{{video.videoId}}"><img class="img-fluid" src="assets/img/v1.png" alt=""></a>
                <div class="time">3:50</div>
              </div>
              <div class="video-card-body">
                <div class="video-title">
                  <a routerLink="/watch/{{video.videoId}}">{{video.title}}</a>
                </div>
                <div class="video-page text-success">
                  {{video.category}} <a title="" data-placement="top" data-toggle="tooltip" href="#" data-original-title="Verified"><i class="fas fa-check-circle text-success"></i></a>
                </div>
                <div class="video-view">
                  {{video.views}} reproducciones &nbsp;<i class="fas fa-calendar-alt"></i> 11 Months ago
                </div>
              </div>
            </div>
          </div>
          <div class="col-xl-3 col-sm-6 mb-3">
            <div class="video-card">
              <div class="video-card-image">
                <a class="play-icon" href="#"><i class="fas fa-play-circle"></i></a>
                <a href="#"><img class="img-fluid" src="assets/img/v2.png" alt=""></a>
                <div class="time">3:50</div>
              </div>
              <div class="video-card-body">
                <div class="video-title">
                  <a href="#">There are many variations of passages of Lorem</a>
                </div>
                <div class="video-page text-success">
                  Education <a title="" data-placement="top" data-toggle="tooltip" href="#" data-original-title="Verified"><i class="fas fa-check-circle text-success"></i></a>
                </div>
                <div class="video-view">
                  1.8M views &nbsp;<i class="fas fa-calendar-alt"></i> 11 Months ago
                </div>
              </div>
            </div>
          </div>
          <div class="col-xl-3 col-sm-6 mb-3">
            <div class="video-card">
              <div class="video-card-image">
                <a class="play-icon" href="#"><i class="fas fa-play-circle"></i></a>
                <a href="#"><img class="img-fluid" src="assets/img/v3.png" alt=""></a>
                <div class="time">3:50</div>
              </div>
              <div class="video-card-body">
                <div class="video-title">
                  <a href="#">There are many variations of passages of Lorem</a>
                </div>
                <div class="video-page text-danger">
                  Education <a title="" data-placement="top" data-toggle="tooltip" href="#" data-original-title="Unverified"><i class="fas fa-frown text-danger"></i></a>
                </div>
                <div class="video-view">
                  1.8M views &nbsp;<i class="fas fa-calendar-alt"></i> 11 Months ago
                </div>
              </div>
            </div>
          </div>
          <div class="col-xl-3 col-sm-6 mb-3">
            <div class="video-card">
              <div class="video-card-image">
                <a class="play-icon" href="#"><i class="fas fa-play-circle"></i></a>
                <a href="#"><img class="img-fluid" src="assets/img/v4.png" alt=""></a>
                <div class="time">3:50</div>
              </div>
              <div class="video-card-body">
                <div class="video-title">
                  <a href="#">There are many variations of passages of Lorem</a>
                </div>
                <div class="video-page text-success">
                  Education <a title="" data-placement="top" data-toggle="tooltip" href="#" data-original-title="Verified"><i class="fas fa-check-circle text-success"></i></a>
                </div>
                <div class="video-view">
                  1.8M views &nbsp;<i class="fas fa-calendar-alt"></i> 11 Months ago
                </div>
              </div>
            </div>
          </div>
          <div class="col-xl-3 col-sm-6 mb-3">
            <div class="video-card">
              <div class="video-card-image">
                <a class="play-icon" href="#"><i class="fas fa-play-circle"></i></a>
                <a href="#"><img class="img-fluid" src="assets/img/v5.png" alt=""></a>
                <div class="time">3:50</div>
              </div>
              <div class="video-card-body">
                <div class="video-title">
                  <a href="#">There are many variations of passages of Lorem</a>
                </div>
                <div class="video-page text-success">
                  Education <a title="" data-placement="top" data-toggle="tooltip" href="#" data-original-title="Verified"><i class="fas fa-check-circle text-success"></i></a>
                </div>
                <div class="video-view">
                  1.8M views &nbsp;<i class="fas fa-calendar-alt"></i> 11 Months ago
                </div>
              </div>
            </div>
          </div>
          <div class="col-xl-3 col-sm-6 mb-3">
            <div class="video-card">
              <div class="video-card-image">
                <a class="play-icon" href="#"><i class="fas fa-play-circle"></i></a>
                <a href="#"><img class="img-fluid" src="assets/img/v6.png" alt=""></a>
                <div class="time">3:50</div>
              </div>
              <div class="video-card-body">
                <div class="video-title">
                  <a href="#">There are many variations of passages of Lorem</a>
                </div>
                <div class="video-page text-danger">
                  Education <a title="" data-placement="top" data-toggle="tooltip" href="#" data-original-title="Unverified"><i class="fas fa-frown text-danger"></i></a>
                </div>
                <div class="video-view">
                  1.8M views &nbsp;<i class="fas fa-calendar-alt"></i> 11 Months ago
                </div>
              </div>
            </div>
          </div>
          <div class="col-xl-3 col-sm-6 mb-3">
            <div class="video-card">
              <div class="video-card-image">
                <a class="play-icon" href="#"><i class="fas fa-play-circle"></i></a>
                <a href="#"><img class="img-fluid" src="assets/img/v7.png" alt=""></a>
                <div class="time">3:50</div>
              </div>
              <div class="video-card-body">
                <div class="video-title">
                  <a href="#">There are many variations of passages of Lorem</a>
                </div>
                <div class="video-page text-success">
                  Education <a title="" data-placement="top" data-toggle="tooltip" href="#" data-original-title="Verified"><i class="fas fa-check-circle text-success"></i></a>
                </div>
                <div class="video-view">
                  1.8M views &nbsp;<i class="fas fa-calendar-alt"></i> 11 Months ago
                </div>
              </div>
            </div>
          </div>
          <div class="col-xl-3 col-sm-6 mb-3">
            <div class="video-card">
              <div class="video-card-image">
                <a class="play-icon" href="#"><i class="fas fa-play-circle"></i></a>
                <a href="#"><img class="img-fluid" src="assets/img/v8.png" alt=""></a>
                <div class="time">3:50</div>
              </div>
              <div class="video-card-body">
                <div class="video-title">
                  <a href="#">There are many variations of passages of Lorem</a>
                </div>
                <div class="video-page text-success">
                  Education <a title="" data-placement="top" data-toggle="tooltip" href="#" data-original-title="Verified"><i class="fas fa-check-circle text-success"></i></a>
                </div>
                <div class="video-view">
                  1.8M views &nbsp;<i class="fas fa-calendar-alt"></i> 11 Months ago
                </div>
              </div>
            </div>
          </div>
          <div class="col-xl-3 col-sm-6 mb-3">
            <div class="video-card">
              <div class="video-card-image">
                <a class="play-icon" href="#"><i class="fas fa-play-circle"></i></a>
                <a href="#"><img class="img-fluid" src="assets/img/v1.png" alt=""></a>
                <div class="time">3:50</div>
              </div>
              <div class="video-card-body">
                <div class="video-title">
                  <a href="#">There are many variations of passages of Lorem</a>
                </div>
                <div class="video-page text-success">
                  Education <a title="" data-placement="top" data-toggle="tooltip" href="#" data-original-title="Verified"><i class="fas fa-check-circle text-success"></i></a>
                </div>
                <div class="video-view">
                  1.8M views &nbsp;<i class="fas fa-calendar-alt"></i> 11 Months ago
                </div>
              </div>
            </div>
          </div>
          <div class="col-xl-3 col-sm-6 mb-3">
            <div class="video-card">
              <div class="video-card-image">
                <a class="play-icon" href="#"><i class="fas fa-play-circle"></i></a>
                <a href="#"><img class="img-fluid" src="assets/img/v2.png" alt=""></a>
                <div class="time">3:50</div>
              </div>
              <div class="video-card-body">
                <div class="video-title">
                  <a href="#">There are many variations of passages of Lorem</a>
                </div>
                <div class="video-page text-success">
                  Education <a title="" data-placement="top" data-toggle="tooltip" href="#" data-original-title="Verified"><i class="fas fa-check-circle text-success"></i></a>
                </div>
                <div class="video-view">
                  1.8M views &nbsp;<i class="fas fa-calendar-alt"></i> 11 Months ago
                </div>
              </div>
            </div>
          </div>
          <div class="col-xl-3 col-sm-6 mb-3">
            <div class="video-card">
              <div class="video-card-image">
                <a class="play-icon" href="#"><i class="fas fa-play-circle"></i></a>
                <a href="#"><img class="img-fluid" src="assets/img/v3.png" alt=""></a>
                <div class="time">3:50</div>
              </div>
              <div class="video-card-body">
                <div class="video-title">
                  <a href="#">There are many variations of passages of Lorem</a>
                </div>
                <div class="video-page text-danger">
                  Education <a title="" data-placement="top" data-toggle="tooltip" href="#" data-original-title="Unverified"><i class="fas fa-frown text-danger"></i></a>
                </div>
                <div class="video-view">
                  1.8M views &nbsp;<i class="fas fa-calendar-alt"></i> 11 Months ago
                </div>
              </div>
            </div>
          </div>
          <div class="col-xl-3 col-sm-6 mb-3">
            <div class="video-card">
              <div class="video-card-image">
                <a class="play-icon" href="#"><i class="fas fa-play-circle"></i></a>
                <a href="#"><img class="img-fluid" src="assets/img/v4.png" alt=""></a>
                <div class="time">3:50</div>
              </div>
              <div class="video-card-body">
                <div class="video-title">
                  <a href="#">There are many variations of passages of Lorem</a>
                </div>
                <div class="video-page text-success">
                  Education <a title="" data-placement="top" data-toggle="tooltip" href="#" data-original-title="Verified"><i class="fas fa-check-circle text-success"></i></a>
                </div>
                <div class="video-view">
                  1.8M views &nbsp;<i class="fas fa-calendar-alt"></i> 11 Months ago
                </div>
              </div>
            </div>
          </div>
        </div>
        <nav aria-label="Page navigation example">
          <ul class="pagination justify-content-center pagination-sm mb-0">
            <li class="page-item disabled">
              <a tabindex="-1" href="#" class="page-link">Previous</a>
            </li>
            <li class="page-item active"><a href="#" class="page-link">1</a></li>
            <li class="page-item"><a href="#" class="page-link">2</a></li>
            <li class="page-item"><a href="#" class="page-link">3</a></li>
            <li class="page-item">
              <a href="#" class="page-link">Next</a>
            </li>
          </ul>
        </nav>
      </div>
    </div>
    <footer class="sticky-footer">
      <div class="container">
        <div class="row no-gutters">
          <div class="col-lg-6 col-sm-6">
            <p class="mt-1 mb-0">© Copyright 2021 <strong class="text-dark">GDON</strong>. Todos Los Derechos Reservados<br>
              <small class="mt-0 mb-0">Hecho con <i class="fas fa-heart text-danger"></i> por <a class="text-primary" target="_blank" href="https://juassic.com/">Juassic Studio</a>
              </small>
            </p>
          </div>
          <div class="col-lg-6 col-sm-6 text-right">
            <div class="app">
              <a href="#" class="mr-2"><img alt="" src="assets/img/google.png"></a>
              <a href="#"><img alt="" src="assets/img/apple.png"></a>
            </div>
          </div>
        </div>
      </div>
    </footer>
  </div>

</div>


<a class="scroll-to-top rounded" href="#page-top">
  <i class="fas fa-angle-up"></i>
</a>

<div class="modal fade" id="logoutModal" tabindex="-1" role="dialog" aria-labelledby="exampleModalLabel" aria-hidden="true">
  <div class="modal-dialog modal-sm modal-dialog-centered" role="document">
    <div class="modal-content">
      <div class="modal-header">
        <h5 class="modal-title" id="exampleModalLabel">Ready to Leave?</h5>
        <button class="close" type="button" data-dismiss="modal" aria-label="Close">
          <span aria-hidden="true">×</span>
        </button>
      </div>
      <div class="modal-body">Select "Logout" below if you are ready to end your current session.</div>
      <div class="modal-footer">
        <button class="btn btn-secondary" type="button" data-dismiss="modal">Cancel</button>
        <a class="btn btn-primary" href="login.html">Logout</a>
      </div>
    </div>
  </div>
</div>
