<div class="main">
  <app-nav [loggedIn]="loggedIn" [user]="usuario"></app-nav>
  <app-footer></app-footer>
  <div id="wrapper">
    <app-sidebar [loggedIn]="loggedIn" [user]="usuario"></app-sidebar>
    <div id="content-wrapper">
      <div class="container-fluid pt-5 pb-5">
        <div class="row">
          <div class="col-md-8 mx-auto text-center upload-video pt-5 pb-5">
            <h1><i class="fas fa-file-upload text-primary"></i></h1>
            <h4 class="mt-5">Selecciona el archivo de video para subir</h4>
            <p class="land">o arrastralo hasta esta zona</p>
            <div class="mt-4">
              <ng-picker-overlay
                [apikey]="apikey"
                (uploadSuccess)="onUploadSuccess($event)"
                (uploadError)="onUploadError($event)">
                <button class="btn btn-outline-primary">Cargar Video</button>
              </ng-picker-overlay>
            </div>
          </div>
        </div>
      </div>
      <app-subfooter></app-subfooter>
    </div>
  </div>
</div>
