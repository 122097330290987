import {Component, OnInit} from '@angular/core';
import { OwlOptions } from 'ngx-owl-carousel-o';
import { PopularChannels } from '../../models/popularchannelsliders';
@Component({
  selector: 'app-popular-channels',
  templateUrl: './popular-channels.component.html',
  styleUrls: ['./popular-channels.component.css']
})

export class PopularChannelsComponent implements OnInit {
  slidesStore: PopularChannels[] = [];
  constructor() { }

  customOptions: OwlOptions = {
    loop: true,
    lazyLoad: true,
    autoplay: true,
    autoplaySpeed: 1000,
    autoplayTimeout: 2000,
    autoplayHoverPause: true,
    nav: true,
    navText: ['<i class="fa fa-chevron-left"></i>', '<i class="fa fa-chevron-right"></i>'],
    responsive: {
      0: {
        items: 1
      },
      600: {
        items: 3,
        nav: false
      },
      1000: {
        items: 4
      },
      1200: {
        items: 8
      }
    }
  };

  ngOnInit(): void {
    this.loadSlides();
  }

  // tslint:disable-next-line:typedef
  loadSlides() {
    this.slidesStore = [
      {
        id: 1
      },
      {
        id: 2
      },
      {
        id: 3
      },
      {
        id: 4
      },
      {
        id: 5
      },
      {
        id: 6
      },
      {
        id: 7
      },
      {
        id: 8
      }
    ];
  }

}
