import { Injectable } from '@angular/core';
import { HttpClient, HttpParams } from '@angular/common/http';
import { environment } from '../../environments/environment';
import { Video } from '../models/video';

@Injectable({providedIn: 'root'})
export class ChannelsService {
  constructor(
    private http: HttpClient,
  ) {
  }

  public subscribe(payload: any): any {
    return this.http.post(`${environment.apiUrl}/subscriptions/subscribe`, payload);
  }

  public unsubscribe(payload: any): any {
    return this.http.post(`${environment.apiUrl}/subscriptions/unsubscribe`, payload);
  }

  public updateNotification(payload: any): any {
    return this.http.post(`${environment.apiUrl}/subscriptions/notification`, payload);
  }

  public checkSubscription(payload: any): any {
    return this.http.get(`${environment.apiUrl}/subscriptions/check/${payload.userEmail}/${payload.channelName}`, {
      params: {
        token: payload.token
      }
    });
  }

  public getSubscriptions(payload: any): any {
    return this.http.get(`${environment.apiUrl}/subscriptions/sidebar`, {
      params: {
        userEmail: payload.userEmail,
        limit: payload.limit ? payload.limit : 0,
        token: payload.token
      }
    });
  }

  public getChannel(payload: any): any {
    return this.http.get(`${environment.apiUrl}/users/channel`, {
      params: {
        username: payload.username
      }
    });
  }
}
