<div class="main">
  <app-nav [loggedIn]="loggedIn" [user]="usuario"></app-nav>
  <app-footer></app-footer>
  <div id="wrapper">
    <app-sidebar [loggedIn]="loggedIn" [user]="usuario"></app-sidebar>
    <div id="content-wrapper">
      <div class="container-fluid pb-0">
        <app-channels></app-channels>
      </div>
      <app-subfooter></app-subfooter>
    </div>
  </div>
</div>
