<footer class="sticky-footer" style="position:fixed;bottom:0;width:85%;">
  <div class="container">
    <div class="row no-gutters">
      <div class="col-lg-6 col-sm-6">
        <p class="mt-1 mb-0">© Copyright 2021 <strong class="text-dark">GDON</strong>. Todos Los Derechos Reservados<br>
          <small class="mt-0 mb-0">Hecho con <i class="fas fa-heart text-danger"></i> por <a class="text-primary" target="_blank" href="https://juassic.com/">Juassic Studio</a>
          </small>
        </p>
      </div>
      <div class="col-lg-6 col-sm-6 text-right">
        <div class="app">
          <a href="#" class="mr-2"><img alt="" src="assets/img/google.png"></a>
          <a href="#"><img alt="" src="assets/img/apple.png"></a>
        </div>
      </div>
    </div>
  </div>
</footer>
