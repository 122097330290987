import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { BehaviorSubject, Observable } from 'rxjs';
import { Router } from '@angular/router';
import { map } from 'rxjs/operators';

import { environment } from '../../environments/environment';
import { User } from '../models/user';

@Injectable({ providedIn: 'root' })
export class AuthenticationService {
  private currentUserSubject: BehaviorSubject<User>;
  public currentUser: Observable<User>;
  public loggedIn: boolean;
  private username: any;

  constructor(
    private http: HttpClient,
    private router: Router
  ) {
    this.currentUserSubject = new BehaviorSubject<User>(JSON.parse(localStorage.getItem('currentUser')));
    this.currentUser = this.currentUserSubject.asObservable();
  }

  public get currentUserValue(): User {
    return this.currentUserSubject.value;
  }

  public getIPAddress(): any {
    return this.http.get('https://api.ipify.org/?format=json');
  }

  login(email: string, password: string): any {
    console.log(`${environment.apiUrl}/login`);
    const login = {
      email,
      password
    };
    return this.http.post<User>(`${environment.apiUrl}/users/login`, login)
      .pipe(map((user) => {
        console.log(user);
        localStorage.setItem('currentUser', JSON.stringify(user));
        this.currentUserSubject.next(user);
        this.loggedIn = true;
        this.router.navigate(['/']);
      }));
  }

  register(usuario: object): any {
    console.log(`${environment.apiUrl}/register`);
    return this.http.post<any>(`${environment.apiUrl}/users/register`, usuario )
      .pipe( map(user => {
        if (user && user.cliente) {
          // store user details and jwt token in local storage to keep user logged in between page refreshes
          localStorage.setItem('currentUser', JSON.stringify(user.cliente));
          this.currentUserSubject.next(user.cliente);
        }
        return user;
      }));
  }

  logout(): any {
    // remove user from local storage to log user out
    localStorage.removeItem('total');
    localStorage.removeItem('aplicacion');
    localStorage.removeItem('datafast');
    localStorage.removeItem('user');
    localStorage.removeItem('cursos');
    localStorage.removeItem('productos');
    localStorage.removeItem('formadepago');
    localStorage.removeItem('promo');
    localStorage.removeItem('cupon');
    localStorage.removeItem('planPaypal');
    localStorage.removeItem('checkoutDatafast');
    localStorage.removeItem('location');
    localStorage.removeItem('currentUser');
    localStorage.removeItem('codigocupon');
    localStorage.removeItem('datosfactura');
    this.currentUserSubject.next(null);
  }
  /*
  postFile(fileToUpload: File): Observable<boolean> {
    const endpoint = 'your-destination-url';
    const formData: FormData = new FormData();
    formData.append('fileKey', fileToUpload, fileToUpload.name);
    return this.HttpClient
      .post(endpoint, formData, { headers: yourHeadersConfig })
      .map(() => { return true; })
      .catch((e) => this.handleError(e));
  }
  */
}
