<div id="page-top">
  <app-nav [loggedIn]="loggedIn" [user]="usuario"></app-nav>
  <div id="wrapper">
    <app-sidebar [loggedIn]="loggedIn" [user]="usuario"></app-sidebar>
    <div id="content-wrapper">
      <div class="container-fluid pb-0">
        <div class="video-block-right-list section-padding">
          <div class="row mb-4">
            <div class="col-md-8">
              <div class="single-video">
                <!-- Paste the following into the <body> -->
                <app-reproductor></app-reproductor>
                <!-- <iframe width="100%" height="315" src="https://www.youtube-nocookie.com/embed/8LWZSGNjuF0?rel=0&amp;controls=0&amp;showinfo=0" frameborder="0" allow="autoplay; encrypted-media" allowfullscreen=""></iframe> -->
              </div>
            </div>
            <div class="col-md-4">
              <div class="video-slider-right-list" style="height:393px;">
                <div class="video-card video-card-list">
                  <div class="video-card-image">
                    <a class="play-icon" href="#"><i class="fas fa-play-circle"></i></a>
                    <a href="#">
                      <img class="img-fluid" src="assets/img/v1.png" alt="">
                    </a>
                    <div class="time">3:50</div>
                  </div>
                  <div class="video-card-body">
                    <div class="btn-group float-right right-action">
                      <a href="#" class="right-action-link text-gray" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                        <i class="fa fa-ellipsis-v" aria-hidden="true"></i>
                      </a>
                      <div class="dropdown-menu dropdown-menu-right">
                        <a class="dropdown-item" href="#"><i class="fas fa-fw fa-star"></i> &nbsp; Ver estadistícas</a>
                        <a class="dropdown-item" href="#"><i class="fas fa-fw fa-signal"></i> &nbsp; Ver</a>
                        <!-- <a class="dropdown-item" href="#"><i class="fas fa-fw fa-times-circle"></i> &nbsp; Close</a> -->
                      </div>
                    </div>
                    <div class="video-title">
                      <a href="#">Minutos de Salud</a>
                    </div>
                    <div class="video-page text-success">
                      Salud <a title="" data-placement="top" data-toggle="tooltip" href="#" data-original-title="Verified"><i class="fas fa-check-circle text-success"></i></a>
                    </div>
                    <div class="video-view">
                      1.8M reporduciones &nbsp;<i class="fas fa-calendar-alt"></i> Hace 11 meses
                    </div>
                  </div>
                </div>
                <!--
                <div class="video-card video-card-list active">
                  <div class="video-card-image">
                    <a class="play-icon" href="#"><i class="fas fa-play-circle"></i></a>
                    <a href="#"><img class="img-fluid" src="assets/img/v2.png" alt=""></a>
                    <div class="time">3:50</div>
                  </div>
                  <div class="video-card-body">
                    <div class="btn-group float-right right-action">
                      <a href="#" class="right-action-link text-gray" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                        <i class="fa fa-ellipsis-v" aria-hidden="true"></i>
                      </a>
                      <div class="dropdown-menu dropdown-menu-right">
                        <a class="dropdown-item" href="#"><i class="fas fa-fw fa-star"></i> &nbsp; Top Rated</a>
                        <a class="dropdown-item" href="#"><i class="fas fa-fw fa-signal"></i> &nbsp; Viewed</a>
                        <a class="dropdown-item" href="#"><i class="fas fa-fw fa-times-circle"></i> &nbsp; Close</a>
                      </div>
                    </div>
                    <div class="video-title">
                      <a href="#">Congreso de alimentaci&oacute;n basado en plantas</a>
                    </div>
                    <div class="video-page text-success">
                      Nutrici&oacute;n y dietetica <a title="" data-placement="top" data-toggle="tooltip" href="#" data-original-title="Verified"><i class="fas fa-check-circle text-success"></i></a>
                    </div>
                    <div class="video-view">
                      1.8M reproduciones &nbsp;<i class="fas fa-calendar-alt"></i> hace 10 meses
                    </div>
                  </div>
                </div>
                <div class="video-card video-card-list">
                  <div class="video-card-image">
                    <a class="play-icon" href="#"><i class="fas fa-play-circle"></i></a>
                    <a href="#">
                      <img class="img-fluid" src="assets/img/v3.png" alt="">
                    </a>
                    <div class="time">3:50</div>
                  </div>
                  <div class="video-card-body">
                    <div class="btn-group float-right right-action">
                      <a href="#" class="right-action-link text-gray" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                        <i class="fa fa-ellipsis-v" aria-hidden="true"></i>
                      </a>
                      <div class="dropdown-menu dropdown-menu-right">
                        <a class="dropdown-item" href="#"><i class="fas fa-fw fa-star"></i> &nbsp; Top Rated</a>
                        <a class="dropdown-item" href="#"><i class="fas fa-fw fa-signal"></i> &nbsp; Viewed</a>
                        <a class="dropdown-item" href="#"><i class="fas fa-fw fa-times-circle"></i> &nbsp; Close</a>
                      </div>
                    </div>
                    <div class="video-title">
                      <a href="#">Seminario de salud - Dieta Plana</a>
                    </div>
                    <div class="video-page text-success">
                      Education <a title="" data-placement="top" data-toggle="tooltip" href="#" data-original-title="Verified"><i class="fas fa-check-circle text-success"></i></a>
                    </div>
                    <div class="video-view">
                      1.8M reproducciones &nbsp;<i class="fas fa-calendar-alt"></i> Hace 24 dias
                    </div>
                  </div>
                </div>
                <div class="video-card video-card-list">
                  <div class="video-card-image">
                    <a class="play-icon" href="#"><i class="fas fa-play-circle"></i></a>
                    <a href="#">
                      <img class="img-fluid" src="assets/img/v4.png" alt="">
                    </a>
                    <div class="time">3:50</div>
                  </div>
                  <div class="video-card-body">
                    <div class="btn-group float-right right-action">
                      <a href="#" class="right-action-link text-gray" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                        <i class="fa fa-ellipsis-v" aria-hidden="true"></i>
                      </a>
                      <div class="dropdown-menu dropdown-menu-right">
                        <a class="dropdown-item" href="#"><i class="fas fa-fw fa-star"></i> &nbsp; Top Rated</a>
                        <a class="dropdown-item" href="#"><i class="fas fa-fw fa-signal"></i> &nbsp; Viewed</a>
                        <a class="dropdown-item" href="#"><i class="fas fa-fw fa-times-circle"></i> &nbsp; Close</a>
                      </div>
                    </div>
                    <div class="video-title">
                      <a href="#">La lengua de cana&aacute;n - Pilares de nuestra fe</a>
                    </div>
                    <div class="video-page text-success">
                      M&uacute;sica <a title="" data-placement="top" data-toggle="tooltip" href="#" data-original-title="Verified"><i class="fas fa-check-circle text-success"></i></a>
                    </div>
                    <div class="video-view">
                      1.8M reproducciones &nbsp;<i class="fas fa-calendar-alt"></i> hace 30 d&iacute;as
                    </div>
                  </div>
                </div>
                <div class="video-card video-card-list">
                  <div class="video-card-image">
                    <a class="play-icon" href="#"><i class="fas fa-play-circle"></i></a>
                    <a href="#"><img class="img-fluid" src="assets/img/v5.png" alt=""></a>
                    <div class="time">3:50</div>
                  </div>
                  <div class="video-card-body">
                    <div class="btn-group float-right right-action">
                      <a href="#" class="right-action-link text-gray" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                        <i class="fa fa-ellipsis-v" aria-hidden="true"></i>
                      </a>
                      <div class="dropdown-menu dropdown-menu-right">
                        <a class="dropdown-item" href="#"><i class="fas fa-fw fa-star"></i> &nbsp; Top Rated</a>
                        <a class="dropdown-item" href="#"><i class="fas fa-fw fa-signal"></i> &nbsp; Viewed</a>
                        <a class="dropdown-item" href="#"><i class="fas fa-fw fa-times-circle"></i> &nbsp; Close</a>
                      </div>
                    </div>
                    <div class="video-title">
                      <a href="#">Exercitation ullamco laboris nisi ut.</a>
                    </div>
                    <div class="video-page text-success">
                      Education <a title="" data-placement="top" data-toggle="tooltip" href="#" data-original-title="Verified"><i class="fas fa-check-circle text-success"></i></a>
                    </div>
                    <div class="video-view">
                      1.8M views &nbsp;<i class="fas fa-calendar-alt"></i> 11 Months ago
                    </div>
                  </div>
                </div>
                <div class="video-card video-card-list">
                  <div class="video-card-image">
                    <a class="play-icon" href="#"><i class="fas fa-play-circle"></i></a>
                    <a href="#"><img class="img-fluid" src="assets/img/v6.png" alt=""></a>
                    <div class="time">3:50</div>
                  </div>
                  <div class="video-card-body">
                    <div class="btn-group float-right right-action">
                      <a href="#" class="right-action-link text-gray" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                        <i class="fa fa-ellipsis-v" aria-hidden="true"></i>
                      </a>
                      <div class="dropdown-menu dropdown-menu-right">
                        <a class="dropdown-item" href="#"><i class="fas fa-fw fa-star"></i> &nbsp; Top Rated</a>
                        <a class="dropdown-item" href="#"><i class="fas fa-fw fa-signal"></i> &nbsp; Viewed</a>
                        <a class="dropdown-item" href="#"><i class="fas fa-fw fa-times-circle"></i> &nbsp; Close</a>
                      </div>
                    </div>
                    <div class="video-title">
                      <a href="#">There are many variations of passages of Lorem</a>
                    </div>
                    <div class="video-page text-success">
                      Education <a title="" data-placement="top" data-toggle="tooltip" href="#" data-original-title="Verified"><i class="fas fa-check-circle text-success"></i></a>
                    </div>
                    <div class="video-view">
                      1.8M views &nbsp;<i class="fas fa-calendar-alt"></i> 11 Months ago
                    </div>
                  </div>
                </div>
                -->
              </div>
            </div>
          </div>
        </div>
        <!--
        <app-top-mobile-search></app-top-mobile-search>
        <app-top-categories></app-top-categories>
        <app-video-block></app-video-block>
        <app-channels></app-channels>
        -->
      </div>
      <app-subfooter></app-subfooter>
    </div>
  </div>
</div>



