import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
import { NgxCaptchaModule } from 'ngx-captcha';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { CommonModule } from '@angular/common';
import { HttpClientModule } from '@angular/common/http';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { NavComponent } from './components/nav/nav.component';
import { FooterComponent } from './components/footer/footer.component';
import { ThemeOneComponent } from './pages/home/theme-one.component';
import { ContentWrapperComponent } from './components/content-wrapper/content-wrapper.component';
import { SidebarComponent } from './components/sidebar/sidebar.component';
import { WrapperComponent } from './components/wrapper/wrapper.component';
import { ContainerFluidComponent } from './components/container-fluid/container-fluid.component';
import { TopMobileSearchComponent } from './components/top-mobile-search/top-mobile-search.component';
import { TopCategoriesComponent } from './components/top-categories/top-categories.component';
import { VideoBlockComponent } from './components/video-block/video-block.component';
import { PopularChannelsComponent } from './components/popular-channels/popular-channels.component';
import { CarouselModule } from 'ngx-owl-carousel-o';
import { ChannelsComponent } from './components/channels/channels.component';
import { CategoriesComponent } from './pages/categories/categories.component';
import { BlogComponent } from './pages/blog/blog.component';
import { CanalesComponent } from './pages/canales/canales.component';
import { CanalComponent } from './pages/canal/canal.component';
import { ArticuloComponent } from './pages/articulo/articulo.component';
import { VideosComponent } from './pages/videos/videos.component';
import { ReproductorComponent } from './components/reproductor/reproductor.component';
import { CategoriaComponent } from './pages/categoria/categoria.component';
import { SubfooterComponent } from './components/subfooter/subfooter.component';
import { UploadComponent } from './pages/upload/upload.component';
import { UploadvideoComponent } from './pages/uploadvideo/uploadvideo.component';
import { HistoryComponent } from './pages/history/history.component';
import { FilestackModule } from '@filestack/angular';
import { ReactiveFormsModule } from '@angular/forms';
import { AccountComponent } from './components/account/account.component';
import { SubscriptionComponent } from './components/subscription/subscription.component';
import { SettingsComponent } from './components/settings/settings.component';
import { LoginComponent } from './pages/login/login.component';
import { RegistroComponent } from './pages/registro/registro.component';
import { LoginCarouselComponent } from './components/login-carousel/login-carousel.component';
import { PlayComponent } from './pages/play/play.component';
import { ReproComponent } from './components/repro/repro.component';
import { Error404Component } from './pages/error/error404/error404.component';

@NgModule({
  declarations: [
    AppComponent,
    NavComponent,
    FooterComponent,
    ThemeOneComponent,
    ContentWrapperComponent,
    SidebarComponent,
    WrapperComponent,
    ContainerFluidComponent,
    TopMobileSearchComponent,
    TopCategoriesComponent,
    VideoBlockComponent,
    PopularChannelsComponent,
    ChannelsComponent,
    CategoriesComponent,
    BlogComponent,
    CanalesComponent,
    CanalComponent,
    ArticuloComponent,
    VideosComponent,
    ReproductorComponent,
    CategoriaComponent,
    SubfooterComponent,
    UploadComponent,
    UploadvideoComponent,
    HistoryComponent,
    AccountComponent,
    SubscriptionComponent,
    SettingsComponent,
    LoginComponent,
    RegistroComponent,
    LoginCarouselComponent,
    PlayComponent,
    ReproComponent,
    Error404Component
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    BrowserAnimationsModule,
    CarouselModule,
    FilestackModule,
    ReactiveFormsModule,
    NgxCaptchaModule,
    CommonModule,
    HttpClientModule,
  ],
  providers: [ ],
  bootstrap: [AppComponent]
})


export class AppModule { }
