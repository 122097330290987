import { Component, OnInit } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import {first, take} from 'rxjs/operators';
// import { AuthenticationService } from '../../services/authentication.service';
import { User } from '../../models/user';
import { AuthenticationService } from '../../services/authentication.service';
import { VideosService } from '../../services/videos.service';

@Component({
  selector: 'app-uploadvideo',
  templateUrl: './uploadvideo.component.html',
  styleUrls: ['./uploadvideo.component.css']
})
export class UploadvideoComponent implements OnInit {
  videoForm: FormGroup;
  returnUrl: string;
  submitted = false;
  loading = false;
  size: 'compact';
  theme: 'light';
  type: 'image';
  lang: 'es';
  urlvideo: string;
  title: string;
  sizevideo: number;
  duration: number;
  file: any;
  myVideos = [];
  usuario: User;
  loggedIn: boolean;
  public orientationOptions: Array<any> = [
    {
      value: 'Automática',
      text: 'Automática'
    },
    {
      value: 'Paisaje',
      text: 'Paisaje'
    },
    {
      value: 'Paisaje',
      text: 'Retrato'
    }
  ];
  public typeOptions: Array<string> = [
    'Público',
    'Privado',
  ];
  public monetizationOptions: Array<string> = [
    'Si',
    'No',
  ];
  public licenseOptions: Array<string> = [
    'Estandar',
    'GNU',
    'Libre'
  ];
  public languageOptions: Array<string> = [
    'Español',
    'Inglés',
    'Francés',
    'Alemán',
    'Italiano'
  ];
  public categoriesOptions: Array<string> = [
    'Cocina vegana',
    'Sermones',
    'Campañas',
    'Conferencias',
    'Seminarios',
    'Películas',
    'Documentales',
    'Historia',
    'Historicos',
    'Homeléticos'
  ];

  public tags: Array<string> = [];
  visible = true;
  selectable = true;
  removable = true;
  addOnBlur = true;
  // readonly separatorKeysCodes: number[] = [ENTER, COMMA];

  constructor(
    private formBuilder: FormBuilder,
    private route: ActivatedRoute,
    private router: Router,
    private videos: VideosService,
    public authenticationService: AuthenticationService
  ) {
  }

  ngOnInit(): void {
    this.usuario = this.authenticationService.currentUserValue;
    this.loggedIn = this.authenticationService.loggedIn;
    console.log(this.usuario);
    console.log(this.loggedIn);

    this.file = JSON.parse(localStorage.getItem('video'));
    this.urlvideo = this.file.filesUploaded[0].url;
    this.title = this.file.filesUploaded[0].filename;
    this.sizevideo = Number(this.file.filesUploaded[0].size);

    this.setFileInfo();

    this.videoForm = this.formBuilder.group({
      title: ['', Validators.required],
      description: ['', Validators.required],
      orientation: ['', Validators.required],
      public: ['', Validators.required],
      monetization: ['', Validators.required],
      license: ['', Validators.required],
      language: ['', Validators.required],
      tags: ['', Validators.required],
      personajes: ['', Validators.required],
      category: ['', null],
      recaptcha: ['', Validators.required]
    });

    // get return url from route parameters or default to '/'
    this.returnUrl = this.route.snapshot.queryParams.returnUrl || '/';
  }

  // convenience getter for easy access to form fields
  get f() { return this.videoForm.controls; }

  setFileInfo(): void {
    const vid = document.getElementById('videouploaded');
    vid.onloadedmetadata = (response) => {
      console.log(response);
      // @ts-ignore
      this.duration = response.target.duration;
      console.log(this.duration);
    };
  }

  millisToMinutesAndSeconds(millis): any {
    const minutes = Math.floor(millis / 60000);
    const seconds = ((millis % 60000) / 1000).toFixed(0);
    // @ts-ignore
    return minutes + ':' + (seconds < 10 ? '0' : '') + seconds;
  }
  /*
  add(event: MatChipInputEvent): void {
    const input = event.input;
    const value = event.value;

    // Add our fruit
    if ((value || '').trim()) {
      this.tags.push(value.trim());
    }

    // Reset the input value
    if (input) {
      input.value = '';
    }
  }
  */
  remove(fruit: any): void {
    const index = this.tags.indexOf(fruit);

    if (index >= 0) {
      this.tags.splice(index, 1);
    }
  }

  onSubmit(): void {
    this.submitted = true;
    const localUser = JSON.parse(localStorage.getItem('currentUser'));

    const payload = {
      title: this.f.title.value,
      description: this.f.description.value,
      orientation: this.f.orientation.value,
      public: this.f.public.value === 'Público',
      monetization: this.f.monetization.value === 'Si',
      license: this.f.license.value,
      language: this.f.language.value,
      category: this.f.category.value,
      tags: this.f.tags.value,
      personajes: this.f.personajes.value,
      token: this.usuario.token,
      likes: 0,
      dislikes: 0,
      views: 0,
      videoUrl: this.file.filesUploaded[0].url,
      videoId: this.file.filesUploaded[0].handle,
      userEmail: this.usuario.email,
      duration: (this.duration / 60),
      size: this.sizevideo / 1000000,
      urlimagen: ''
    };

    console.log(payload);

    // stop here if form is invalid
    if (this.videoForm.invalid) {
      return;
    }
    this.videos.uploadVideo(payload)
      .subscribe(() => {
        this.router.navigate(['/']);
      });
  }

  handleSuccess(event): void {
    console.log(event);
  }

  handleLoad(): void {
    console.log( 'Esta cargando' );
  }

  handleExpire(): void {
    console.log('captcha ha expirado');
  }

  handleReset(): void {
    console.log('captcha ha sido reseteado');
  }

  siteKey(): string {
    return '6LdHqJ4UAAAAAM_qCsBbBmXYecwD2Vuo9klZIPEB';
  }

}
