import {Component, Input, OnInit} from '@angular/core';
import {AuthenticationService} from '../../services/authentication.service';
import { Router } from '@angular/router';
import { User } from '../../models/user';

@Component({
  selector: 'app-nav',
  templateUrl: './nav.component.html',
  styleUrls: ['./nav.component.css']
})
export class NavComponent implements OnInit {
  @Input() loggedIn;
  public homeLink: any;
  @Input() user;
  public username = 'Invitado';
  constructor(
    private auth: AuthenticationService,
    private route: Router
  ) {}

  ngOnInit(): void {
    this.user = this.auth.currentUserValue;
    console.log(this.user);
    if (this.user && this.user.username !== '') {
      this.username = this.user.username;
    }
    if (this.loggedIn) {
      this.homeLink = '/';
    } else {
      this.homeLink = '/video';
      //this.route.navigate(['/video']);
    }
  }

  public logOut(): void {
    // this.route.onSameUrlNavigation = 'reload';
    console.log(this.route.url);
    localStorage.removeItem('total');
    localStorage.removeItem('aplicacion');
    localStorage.removeItem('datafast');
    localStorage.removeItem('user');
    localStorage.removeItem('cursos');
    localStorage.removeItem('productos');
    localStorage.removeItem('formadepago');
    localStorage.removeItem('promo');
    localStorage.removeItem('cupon');
    localStorage.removeItem('planPaypal');
    localStorage.removeItem('checkoutDatafast');
    localStorage.removeItem('location');
    localStorage.removeItem('currentUser');
    localStorage.removeItem('codigocupon');
    localStorage.removeItem('datosfactura');
    this.route.navigateByUrl(this.route.url)//.then(()=>
    //   this.route.navigate([this.route.url]));
  }

}
