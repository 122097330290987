<div class="video-block section-padding">
  <div class="row">
    <div class="col-md-12">
      <div class="main-title">
        <div class="btn-group float-right right-action">
          <a href="#" class="right-action-link text-gray" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
            Ordenar por <i class="fa fa-caret-down" aria-hidden="true"></i>
          </a>
          <div class="dropdown-menu dropdown-menu-right">
            <a class="dropdown-item" href="#"><i class="fas fa-fw fa-star"></i> &nbsp; Los mas vistos</a>
            <a class="dropdown-item" href="#"><i class="fas fa-fw fa-signal"></i> &nbsp; Los mas populares</a>
            <a class="dropdown-item" href="#"><i class="fas fa-fw fa-times-circle"></i> &nbsp; Close</a>
          </div>
        </div>
        <h6>Popular Channels</h6>
      </div>
    </div>

      <owl-carousel-o class="mb-3" [options]="customOptions">
        <ng-container *ngFor="let slide of slidesStore">
          <ng-template carouselSlide>
            <div class="channels-card">
              <div class="channels-card-image">
                <a href="#">
                  <img class="img-fluid" src="assets/img/s1.png" alt="">
                </a>
                <div class="channels-card-image-btn"><button type="button" class="btn btn-outline-danger btn-sm">Subscribe <strong>1.4M</strong></button></div>
              </div>
              <div class="channels-card-body">
                <div class="channels-title">
                  <a href="#">Channels Name</a>
                </div>
                <div class="channels-view">
                  382,323 subscribers
                </div>
              </div>
            </div>
          </ng-template>
        </ng-container>
      </owl-carousel-o>


  </div>
</div>
