<app-nav [loggedIn]="loggedIn" [user]="usuario"></app-nav>
<div id="wrapper">
  <app-sidebar [loggedIn]="loggedIn" [user]="usuario"></app-sidebar>
  <div id="content-wrapper">
    <div class="container-fluid">
      <section class="blog-page section-padding">
        <div class="container">
          <div class="row">
            <div class="col-md-12">
              <div class="main-title">
                <div class="btn-group float-right right-action">
                  <a aria-expanded="false" aria-haspopup="true" data-toggle="dropdown" class="right-action-link text-gray" href="#">
                    Sort by <i aria-hidden="true" class="fa fa-caret-down"></i>
                  </a>
                  <div class="dropdown-menu dropdown-menu-right">
                    <a href="#" class="dropdown-item"><i class="fas fa-fw fa-star"></i> &nbsp; Top Rated</a>
                    <a href="#" class="dropdown-item"><i class="fas fa-fw fa-signal"></i> &nbsp; Viewed</a>
                    <a href="#" class="dropdown-item"><i class="fas fa-fw fa-times-circle"></i> &nbsp; Close</a>
                  </div>
                </div>
                <h6>El Blog de GDON-TV</h6>
              </div>
            </div>
            <div class="col-md-8">
              <div class="card blog mb-4">
                <div class="blog-header">
                  <a routerLink="/articulo"><img class="card-img-top" src="assets/img/blog/1.png" alt="Card image cap"></a>
                </div>
                <div class="card-body">
                  <h5 class="card-title"><a routerLink="/articulo">Aliquam euismod libero eu enim. Nulla nec felis sed leo.</a></h5>
                  <div class="entry-meta">
                    <ul class="tag-info list-inline">
                      <li class="list-inline-item"><a href="#"><i class="fas fa-calendar"></i> March 6, 2020</a></li>
                      <li class="list-inline-item"><i class="fas fa-folder"></i> <a rel="category tag" href="#">Image</a></li>
                      <li class="list-inline-item"><i class="fas fa-tag"></i> <a rel="tag" href="#">envato</a>, <a rel="tag" href="#">sale</a>, <a rel="tag" href="#">shop</a> </li>
                      <li class="list-inline-item"><i class="fas fa-comment"></i> <a href="#">4 Comments</a></li>
                    </ul>
                  </div>
                  <p class="card-text">Aliquam convallis sollicitudin purus. Praesent aliquam, enim at fermentum mollis, ligula massa adipiscing nisl, ac euismod nibh nisl eu lectus. Fusce vulputate sem at sapien. Vivamus leo. Aliquam euismod libero eu enim. Nulla nec felis sed leo placerat imperdiet. Aenean suscipit nulla in justo. Suspendisse cursus rutrum augue. Nulla tincidunt tincidunt mi. Lorem ipsum dolor
                  </p>
                  <a routerLink="/articulo">LEER MAS <span class="fas fa-chevron-right"></span></a>
                </div>
              </div>
              <div class="card blog mb-4">
                <div class="blog-header">
                  <a routerLink="/articulo"><img class="card-img-top" src="assets/img/blog/2.png" alt="Card image cap"></a>
                </div>
                <div class="card-body">
                  <h5 class="card-title"><a routerLink="/articulo">Aliquam euismod libero eu enim. Nulla nec felis sed leo.</a></h5>
                  <div class="entry-meta">
                    <ul class="tag-info list-inline">
                      <li class="list-inline-item"><a href="#"><i class="fas fa-calendar"></i> March 6, 2020</a></li>
                      <li class="list-inline-item"><i class="fas fa-folder"></i> <a rel="category tag" href="#">Image</a></li>
                      <li class="list-inline-item"><i class="fas fa-tag"></i> <a rel="tag" href="#">envato</a>, <a rel="tag" href="#">sale</a>, <a rel="tag" href="#">shop</a> </li>
                      <li class="list-inline-item"><i class="fas fa-comment"></i> <a href="#">4 Comments</a></li>
                    </ul>
                  </div>
                  <p class="card-text">Aliquam convallis sollicitudin purus. Praesent aliquam, enim at fermentum mollis, ligula massa adipiscing nisl, ac euismod nibh nisl eu lectus. Fusce vulputate sem at sapien. Vivamus leo. Aliquam euismod libero eu enim. Nulla nec felis sed leo placerat imperdiet. Aenean suscipit nulla in justo. Suspendisse cursus rutrum augue. Nulla tincidunt tincidunt mi. Lorem ipsum dolor
                  </p>
                  <a routerLink="/articulo">LEER MAS <span class="fas fa-chevron-right"></span></a>
                </div>
              </div>
              <div class="card blog mb-4">
                <div class="blog-header">
                  <a routerLink="/articulo"><img class="card-img-top" src="assets/img/blog/3.png" alt="Card image cap"></a>
                </div>
                <div class="card-body">
                  <h5 class="card-title"><a routerLink="/articulo">Aliquam euismod libero eu enim. Nulla nec felis sed leo.</a></h5>
                  <div class="entry-meta">
                    <ul class="tag-info list-inline">
                      <li class="list-inline-item"><a routerLink="/articulo"><i class="fas fa-calendar"></i> March 6, 2020</a></li>
                      <li class="list-inline-item"><i class="fas fa-folder"></i> <a rel="category tag" href="#">Image</a></li>
                      <li class="list-inline-item"><i class="fas fa-tag"></i> <a rel="tag" href="#">envato</a>, <a rel="tag" href="#">sale</a>, <a rel="tag" href="#">shop</a> </li>
                      <li class="list-inline-item"><i class="fas fa-comment"></i> <a href="#">4 Comments</a></li>
                    </ul>
                  </div>
                  <p class="card-text">Aliquam convallis sollicitudin purus. Praesent aliquam, enim at fermentum mollis, ligula massa adipiscing nisl, ac euismod nibh nisl eu lectus. Fusce vulputate sem at sapien. Vivamus leo. Aliquam euismod libero eu enim. Nulla nec felis sed leo placerat imperdiet. Aenean suscipit nulla in justo. Suspendisse cursus rutrum augue. Nulla tincidunt tincidunt mi. Lorem ipsum dolor
                  </p>
                  <a routerLink="/articulo">READ MORE <span class="fas fa-chevron-right"></span></a>
                </div>
              </div>
              <ul class="pagination justify-content-center mt-4 pagination-sm">
                <li class="page-item disabled">
                  <span class="page-link">Anterior</span>
                </li>
                <li class="page-item"><a href="#" class="page-link">1</a></li>
                <li class="page-item active">
                  <span class="page-link">
                  2
                   <span class="sr-only">(current)</span>
                  </span>
                </li>
                <li class="page-item"><a href="#" class="page-link">3</a></li>
                <li class="page-item">
                  <a href="#" class="page-link">Siguiente</a>
                </li>
              </ul>
            </div>
            <div class="col-md-4">
              <div class="card sidebar-card mb-4">
                <div class="card-body">
                  <div class="input-group">
                    <input type="text" placeholder="Que quieres leer" class="form-control">
                    <div class="input-group-append">
                      <button type="button" class="btn btn-secondary">Buscar <i class="fas fa-arrow-right"></i></button>
                    </div>
                  </div>
                </div>
              </div>
              <div class="card sidebar-card mb-4">
                <div class="card-body">
                  <h5 class="card-title mb-3">Categorías</h5>
                  <ul class="sidebar-card-list">
                    <li><a href="#"><i class="fas fa-chevron-right"></i> Interesantes</a></li>
                    <li><a href="#"><i class="fas fa-chevron-right"></i> Descubrimientos</a></li>
                    <li><a href="#"><i class="fas fa-chevron-right"></i> Salud</a></li>
                    <li><a href="#"><i class="fas fa-chevron-right"></i> General</a></li>
                    <li><a href="#"><i class="fas fa-chevron-right"></i> Estudios</a></li>
                  </ul>
                </div>
              </div>
              <div class="card sidebar-card mb-4">
                <div class="card-body">
                  <h5 class="card-title mb-3">Archives</h5>
                  <ul class="sidebar-card-list">
                    <li><a href="#"><i class="fas fa-chevron-right"></i> Diciembre, 2020</a></li>
                    <li><a href="#"><i class="fas fa-chevron-right"></i> Noviembre, 2020</a></li>
                    <li><a href="#"><i class="fas fa-chevron-right"></i> Octubre, 2020</a></li>
                  </ul>
                </div>
              </div>
              <div class="card sidebar-card mb-4">
                <div class="card-body">
                  <h5 class="card-title mb-3">Etiquetas</h5>
                  <div class="tagcloud">
                    <a class="tag-cloud-link" href="#">conquistas</a>
                    <a class="tag-cloud-link" href="#">biblicas</a>
                    <a class="tag-cloud-link" href="#">enciclicas</a>
                    <a class="tag-cloud-link" href="#">vegetarianismo</a>
                    <a class="tag-cloud-link" href="#">recetas</a>
                    <a class="tag-cloud-link" href="#">cursos</a>
                    <a class="tag-cloud-link" href="#">clases</a>
                    <a class="tag-cloud-link" href="#">videos</a>
                    <a class="tag-cloud-link" href="#">teologicos</a>
                  </div>
                </div>
              </div>
              <div class="card sidebar-card mb-4">
                <div class="card-body">
                  <h5 class="card-title mb-4">Newsletter</h5>
                  <div class="input-group">
                    <input type="text" placeholder="Tu email" class="form-control">
                    <div class="input-group-append">
                      <button type="button" class="btn btn-secondary">Registrate <i class="fas fa-arrow-right"></i></button>
                    </div>
                  </div>
                </div>
              </div>
              <div class="card sidebar-card mb-4">
                <div class="card-body">
                  <h5 class="card-title mb-3">Meta</h5>
                  <ul class="sidebar-card-list">
                    <li><a href="#"><i class="fas fa-chevron-right"></i> Log in</a></li>
                    <li><a href="#"><i class="fas fa-chevron-right"></i> Entries RSS</a></li>
                    <li><a href="#"><i class="fas fa-chevron-right"></i> Comments RSS</a></li>
                    <li><a href="#"><i class="fas fa-chevron-right"></i> WordPress.org</a></li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </div>


    <app-subfooter></app-subfooter>
  </div>

</div>


<a class="scroll-to-top rounded" href="#page-top">
  <i class="fas fa-angle-up"></i>
</a>

<div class="modal fade" id="logoutModal" tabindex="-1" role="dialog" aria-labelledby="exampleModalLabel" aria-hidden="true">
  <div class="modal-dialog modal-sm modal-dialog-centered" role="document">
    <div class="modal-content">
      <div class="modal-header">
        <h5 class="modal-title" id="exampleModalLabel">Ready to Leave?</h5>
        <button class="close" type="button" data-dismiss="modal" aria-label="Close">
          <span aria-hidden="true">×</span>
        </button>
      </div>
      <div class="modal-body">Select "Logout" below if you are ready to end your current session.</div>
      <div class="modal-footer">
        <button class="btn btn-secondary" type="button" data-dismiss="modal">Cancel</button>
        <a class="btn btn-primary" href="login.html">Logout</a>
      </div>
    </div>
  </div>
</div>
