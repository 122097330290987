import {Injectable} from '@angular/core';

@Injectable({providedIn: 'root'})
export class UtilsService {
  private now = new Date();
  constructor(
  ) {
  }

  public getDateDiff(date: Date): string {
    return this.getDateDiffSecs((this.now.getTime() - date.getTime()) / 1000);
  }

  private getDateDiffSecs(seconds: number): string {
    if (seconds > 60) {
      return this.getDateDiffMins(seconds / 60);
    }
    return (seconds >= 2) ? `${seconds.toFixed(0)} segundos` : `${seconds.toFixed(0)} segundo`;
  }

  private getDateDiffMins(mins: number): string {
    if (mins > 60) {
      return this.getDateDiffHours(mins / 60);
    }
    return (mins >= 2) ? `${mins.toFixed(0)} minutos` : `${mins.toFixed(0)} minuto`;
  }

  private getDateDiffHours(hours: number): string {
    if (hours > 24) {
      return this.getDateDiffDays(hours / 24);
    }
    return (hours >= 2) ? `${hours.toFixed(0)} horas` : `${hours.toFixed(0)} hora`;
  }

  private getDateDiffDays(days: number): string {
    if (days > 6) {
      return this.getDateDiffWeeks(days / 7);
    }
    return (days >= 2) ? `${days.toFixed(0)} dias` : `${days.toFixed(0)} dia`;
  }

  private getDateDiffWeeks(weeks: number): string {
    if (weeks > 3) {
      return this.getDateDiffMonths(weeks / 4);
    }
    return (weeks >= 2) ? `${weeks.toFixed(0)} semanas` : `${weeks.toFixed(0)} semana`;
  }

  private getDateDiffMonths(months: number): string {
    if (months > 11) {
      return (months / 12 >= 2) ? `${(months / 12).toFixed(0)} años` : `${(months / 12).toFixed(0)} año`;
    }
    return (months >= 2) ? `${months.toFixed(0)} meses` : `${months.toFixed(0)} mes`;
  }

  public getVideoDuration(duration: number): string {
    if (duration >= 60) {
      if (duration >= 3600) {
        const hours = Math.floor(duration / 3600);
        const mins = Math.floor((duration % 3600) / 60);
        const secs = Math.floor(((duration % 3600) % 60) / 60);
        return `${this.getTimeText(hours)}:${this.getTimeText(mins)}:${this.getTimeText(secs)}`;
      } else {
        const mins = Math.floor(duration / 60);
        const secs = Math.floor((duration % 60) / 60);
        return `${this.getTimeText(mins)}:${this.getTimeText(secs)}`;
      }
    } else {
      return `00:${this.getTimeText(duration % 60)}`;
    }
  }

  private getTimeText(time: number): string {
    return time < 10 ? `0${time}` : `${time}`;
  }
}
