<app-nav [loggedIn]="loggedIn" [user]="usuario"></app-nav>
<div id="wrapper">
  <app-sidebar [loggedIn]="loggedIn" [user]="usuario"></app-sidebar>
  <div id="content-wrapper">
    <div class="container-fluid">
      <section class="blog-page section-padding">
        <div class="container">
          <div class="row">
            <div class="col-md-12">
              <div class="main-title">
                <div class="btn-group float-right right-action">
                  <a aria-expanded="false" aria-haspopup="true" data-toggle="dropdown" class="right-action-link text-gray" href="#">
                    Ordenar por <i aria-hidden="true" class="fa fa-caret-down"></i>
                  </a>
                  <div class="dropdown-menu dropdown-menu-right">
                    <a href="#" class="dropdown-item"><i class="fas fa-fw fa-star"></i> &nbsp; Top Rated</a>
                    <a href="#" class="dropdown-item"><i class="fas fa-fw fa-signal"></i> &nbsp; Viewed</a>
                    <a href="#" class="dropdown-item"><i class="fas fa-fw fa-times-circle"></i> &nbsp; Close</a>
                  </div>
                </div>
                <h6>Blog - Aliquam euismod libero eu enim. Nulla nec felis sed leo.</h6>
              </div>
            </div>
            <div class="col-md-8">
              <div class="card blog mb-4">
                <div class="blog-header">
                  <a href="#"><img class="card-img-top" src="assets/img/blog/2.png" alt="Card image cap"></a>
                </div>
                <div class="card-body">
                  <h5 class="card-title"><a href="#">Aliquam euismod libero eu enim. Nulla nec felis sed leo.</a></h5>
                  <div class="entry-meta">
                    <ul class="tag-info list-inline">
                      <li class="list-inline-item"><a href="#"><i class="fas fa-calendar"></i> March 6, 2020</a></li>
                      <li class="list-inline-item"><i class="fas fa-folder"></i> <a rel="category tag" href="#">Image</a></li>
                      <li class="list-inline-item"><i class="fas fa-tag"></i> <a rel="tag" href="#">envato</a>, <a rel="tag" href="#">sale</a>, <a rel="tag" href="#">shop</a> </li>
                      <li class="list-inline-item"><i class="fas fa-comment"></i> <a href="#">4 Comments</a></li>
                    </ul>
                  </div>
                  <p>Aliquam convallis sollicitudin purus. Praesent aliquam, enim at fermentum mollis, ligula massa adipiscing nisl, ac euismod nibh nisl eu lectus. Fusce vulputate sem at sapien. Vivamus leo. Aliquam euismod libero eu enim. Nulla nec felis sed leo placerat imperdiet. Aenean suscipit nulla in justo. Suspendisse cursus rutrum augue. Nulla tincidunt tincidunt mi.</p>
                  <blockquote class="blockquote">
                    <p class="mb-0">Lorem ipsum dolor sit amet, consectetur adipiscing elit. Integer posuere erat a ante.</p>
                    <footer class="blockquote-footer">Someone famous in <cite title="Source Title">Source Title</cite></footer>
                  </blockquote>
                  <p>Praesent aliquam, enim at fermentum mollis, ligula massa adipiscing nisl, ac euismod nibh nisl eu lectus. Fusce vulputate sem at sapien. Vivamus leo. Aliquam euismod libero eu enim. Nulla nec felis sed leo placerat imperdiet. Aenean suscipit nulla in justo.</p>
                  <h5 class="mb-4">Lorem ipsum dolor sit amet, consectetur.</h5>
                  <div class="gallery mb-4">
                    <div class="row">
                      <div class="col-sm-4"><img class="rounded" alt="" src="assets/img/blog/3.png"></div>
                      <div class="col-sm-4"><img class="rounded" alt="" src="assets/img/blog/2.png"></div>
                      <div class="col-sm-4"><img class="rounded" alt="" src="assets/img/blog/1.png"></div>
                    </div>
                  </div>
                  <p>Praesent aliquam, enim at fermentum mollis, ligula massa adipiscing nisl, ac euismod nibh nisl eu lectus. Fusce vulputate sem at sapien. Vivamus leo. Aliquam euismod libero eu enim. Nulla nec felis sed leo placerat imperdiet. Aenean suscipit nulla in justo.</p>
                  <h5 class="mb-2">Qui cumque numquam ?</h5>
                  <p>Lorem ipsum dolor sit amet, consectetur adipisicing elit. Possimus a nobis, vero in corrupti nostrum. Magni reprehenderit tempora tempore maiores, repellat in laboriosam aliquid ex error, iusto quae, et similique.</p>
                  <p>Lorem ipsum dolor sit amet, consectetur adipisicing elit. Reiciendis sunt a quam, exercitationem, provident numquam quia eius optio quos vitae odio impedit ipsum voluptatem earum neque architecto enim quisquam, ea.</p>
                  <p>Lorem ipsum dolor sit amet, consectetur adipisicing elit. Non, ipsum facilis, eaque aliquid porro deleniti architecto eum perferendis quidem modi quo reiciendis dolor deserunt beatae repellat, nostrum aut fuga. Rem?</p>
                  <div class="gallery mt-4 mb-4">
                    <div class="row">
                      <div class="col-sm-6"><img alt="" class="rounded" src="assets/img/blog/2.png"></div>
                      <div class="col-sm-6"><img alt="" class="rounded" src="assets/img/blog/3.png"></div>
                    </div>
                  </div>
                  <p>Lorem ipsum dolor sit amet, consectetur adipisicing elit. Non, ipsum facilis, eaque aliquid porro deleniti architecto eum perferendis quidem modi quo reiciendis dolor deserunt beatae repellat, nostrum aut fuga. Rem?</p>
                  <p>Lorem ipsum dolor sit amet, consectetur adipisicing elit. Reiciendis sunt a quam, exercitationem, provident numquam quia eius optio quos vitae odio impedit ipsum voluptatem earum neque architecto enim quisquam, ea.</p>
                  <footer class="entry-footer">
                    <div class="blog-post-tags">
                      <ul class="list-inline">
                        <li class="list-inline-item"><i class="fas fa-tag"></i> Tags: </li>
                        <li class="list-inline-item"><a rel="tag" href="#">envato</a> </li>
                        <li class="list-inline-item"><a rel="tag" href="#">sale</a> </li>
                        <li class="list-inline-item"><a rel="tag" href="#">shop</a> </li>
                      </ul>
                    </div>
                  </footer>
                </div>
              </div>
              <div class="card padding-card reviews-card mb-4">
                <div class="card-body">
                  <h5 class="card-title mb-4">3 Valoraciones</h5>
                  <div class="media mb-4">
                    <img alt="" src="assets/img/s2.png" class="d-flex mr-3 rounded">
                    <div class="media-body">
                      <h5 class="mt-0">Stave Martin <small>Feb 12, 2020</small>
                        <span class="star-rating float-right">
                        <i class="fas fa-star text-warning"></i>
                        <i class="fas fa-star text-warning"></i>
                        <i class="fas fa-star text-warning"></i>
                        <i class="fas fa-star-half text-warning"></i>
                        <i class="fas fa-star-half text-warning"></i><small class="text-success">5/2</small>
                        </span>
                      </h5>
                      <p>Cras sit amet nibh libero, in gravida nulla. Nulla vel metus scelerisque ante sollicitudin. Cras purus odio, vestibulum in vulputate at, tempus viverra turpis. Fusce condimentum nunc ac nisi vulputate fringilla. Donec lacinia congue felis in faucibus.</p>
                    </div>
                  </div>
                  <div class="media">
                    <img alt="" src="assets/img/s1.png" class="d-flex mr-3 rounded">
                    <div class="media-body">
                      <h5 class="mt-0">Mark Smith <small>Feb 09, 2020</small> <span class="star-rating float-right">
                      <i class="fas fa-star text-warning"></i>
                      <i class="fas fa-star text-warning"></i>
                      <i class="fas fa-star-half text-warning"></i>
                      <i class="fas fa-star-half text-warning"></i>
                      <i class="fas fa-star-half text-warning"></i><small class="text-success">5/1</small>
                      </span>
                      </h5>
                      <p>Cras sit amet nibh libero, in gravida nulla. Nulla vel metus scelerisque ante sollicitudin. Cras purus odio, vestibulum in vulputate at, tempus viverra turpis. Fusce condimentum nunc ac nisi vulputate fringilla. Donec lacinia congue felis in faucibus.</p>
                      <div class="media mt-4">
                        <img alt="" src="assets/img/s3.png" class="d-flex mr-3 rounded">
                        <div class="media-body">
                          <h5 class="mt-0">Ryan Printz <small>Nov 13, 2020</small>
                            <span class="star-rating float-right">
                                <i class="fas fa-star text-warning"></i>
                                <i class="fas fa-star text-warning"></i>
                                <i class="fas fa-star-half text-warning"></i>
                                <i class="fas fa-star-half text-warning"></i>
                                <i class="fas fa-star-half text-warning"></i><small class="text-success">5/5</small>
                            </span>
                          </h5>
                          <p>Cras sit amet nibh libero, in gravida nulla. Nulla vel metus scelerisque ante sollicitudin. Cras purus odio, vestibulum in vulputate at, tempus viverra turpis. Fusce condimentum nunc ac nisi vulputate fringilla. Donec lacinia congue felis in faucibus.</p>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div class="media mt-4">
                    <img alt="" src="assets/img/s4.png" class="d-flex mr-3 rounded">
                    <div class="media-body">
                      <h5 class="mt-0">Stave Mark <small>Feb 12, 2020</small>
                        <span class="star-rating float-right">
                            <i class="fas fa-star text-warning"></i>
                            <i class="fas fa-star text-warning"></i>
                            <i class="fas fa-star text-warning"></i>
                            <i class="fas fa-star-half text-warning"></i>
                            <i class="fas fa-star-half text-warning"></i><small class="text-success">5/2</small>
                        </span>
                      </h5>
                      <p class="mb-0">Cras sit amet nibh libero, in gravida nulla. Nulla vel metus scelerisque ante sollicitudin. Cras purus odio, vestibulum in vulputate at, tempus viverra turpis. Fusce condimentum nunc ac nisi vulputate fringilla. Donec lacinia congue felis in faucibus.</p>
                    </div>
                  </div>
                </div>
              </div>
              <div class="card blog">
                <div class="card-body">
                  <h5 class="card-title mb-4">Escribe una reseña</h5>
                  <form name="sentMessage">
                    <div class="row">
                      <div class="control-group form-group col-lg-6 col-md-6">
                        <div class="controls">
                          <label>Tu nombre <span class="text-danger">*</span></label>
                          <input type="text" required="" class="form-control">
                        </div>
                      </div>
                      <div class="control-group form-group col-lg-6 col-md-6">
                        <div class="controls">
                          <label>Tu Email <span class="text-danger">*</span></label>
                          <input type="email" required="" class="form-control">
                        </div>
                      </div>
                    </div>
                    <div class="control-group form-group">
                      <div class="controls">
                        <label>Comentario <span class="text-danger">*</span></label>
                        <textarea class="form-control" cols="100" rows="10"></textarea>
                      </div>
                    </div>
                    <button class="btn btn-success" type="submit">Enviar</button>
                  </form>
                </div>
              </div>
            </div>
            <div class="col-md-4">
              <div class="card sidebar-card mb-4">
                <div class="card-body">
                  <div class="input-group">
                    <input type="text" placeholder="Que quieres leer" class="form-control">
                    <div class="input-group-append">
                      <button type="button" class="btn btn-secondary">Buscar <i class="fas fa-arrow-right"></i></button>
                    </div>
                  </div>
                </div>
              </div>
              <div class="card sidebar-card mb-4">
                <div class="card-body">
                  <h5 class="card-title mb-3">Categorías</h5>
                  <ul class="sidebar-card-list">
                    <li><a href="#"><i class="fas fa-chevron-right"></i> Interesantes</a></li>
                    <li><a href="#"><i class="fas fa-chevron-right"></i> Descubrimientos</a></li>
                    <li><a href="#"><i class="fas fa-chevron-right"></i> Salud</a></li>
                    <li><a href="#"><i class="fas fa-chevron-right"></i> General</a></li>
                    <li><a href="#"><i class="fas fa-chevron-right"></i> Estudios</a></li>
                  </ul>
                </div>
              </div>
              <div class="card sidebar-card mb-4">
                <div class="card-body">
                  <h5 class="card-title mb-3">Archives</h5>
                  <ul class="sidebar-card-list">
                    <li><a href="#"><i class="fas fa-chevron-right"></i> Diciembre, 2020</a></li>
                    <li><a href="#"><i class="fas fa-chevron-right"></i> Noviembre, 2020</a></li>
                    <li><a href="#"><i class="fas fa-chevron-right"></i> Octubre, 2020</a></li>
                  </ul>
                </div>
              </div>
              <div class="card sidebar-card mb-4">
                <div class="card-body">
                  <h5 class="card-title mb-3">Etiquetas</h5>
                  <div class="tagcloud">
                    <a class="tag-cloud-link" href="#">conquistas</a>
                    <a class="tag-cloud-link" href="#">biblicas</a>
                    <a class="tag-cloud-link" href="#">enciclicas</a>
                    <a class="tag-cloud-link" href="#">vegetarianismo</a>
                    <a class="tag-cloud-link" href="#">recetas</a>
                    <a class="tag-cloud-link" href="#">cursos</a>
                    <a class="tag-cloud-link" href="#">clases</a>
                    <a class="tag-cloud-link" href="#">videos</a>
                    <a class="tag-cloud-link" href="#">teologicos</a>
                  </div>
                </div>
              </div>
              <div class="card sidebar-card mb-4">
                <div class="card-body">
                  <h5 class="card-title mb-4">Newsletter</h5>
                  <div class="input-group">
                    <input type="text" placeholder="Tu email" class="form-control">
                    <div class="input-group-append">
                      <button type="button" class="btn btn-secondary">Registrate <i class="fas fa-arrow-right"></i></button>
                    </div>
                  </div>
                </div>
              </div>
              <div class="card sidebar-card mb-4">
                <div class="card-body">
                  <h5 class="card-title mb-3">Meta</h5>
                  <ul class="sidebar-card-list">
                    <li><a href="#"><i class="fas fa-chevron-right"></i> Log in</a></li>
                    <li><a href="#"><i class="fas fa-chevron-right"></i> Entries RSS</a></li>
                    <li><a href="#"><i class="fas fa-chevron-right"></i> Comments RSS</a></li>
                    <li><a href="#"><i class="fas fa-chevron-right"></i> WordPress.org</a></li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </div>
    <app-subfooter></app-subfooter>
  </div>

</div>


<a class="scroll-to-top rounded" href="#page-top">
  <i class="fas fa-angle-up"></i>
</a>

<div class="modal fade" id="logoutModal" tabindex="-1" role="dialog" aria-labelledby="exampleModalLabel" aria-hidden="true">
  <div class="modal-dialog modal-sm modal-dialog-centered" role="document">
    <div class="modal-content">
      <div class="modal-header">
        <h5 class="modal-title" id="exampleModalLabel">Ready to Leave?</h5>
        <button class="close" type="button" data-dismiss="modal" aria-label="Close">
          <span aria-hidden="true">×</span>
        </button>
      </div>
      <div class="modal-body">Select "Logout" below if you are ready to end your current session.</div>
      <div class="modal-footer">
        <button class="btn btn-secondary" type="button" data-dismiss="modal">Cancel</button>
        <a class="btn btn-primary" href="login.html">Logout</a>
      </div>
    </div>
  </div>
</div>
