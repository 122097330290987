<ul class="sidebar navbar-nav">
  <li *ngFor="let navItem of ((user && user.username !== '') ? fullNavigationItems : navigationItems)" [class]="navItem.class">
    <a class="nav-link" [routerLink]="navItem.routerLink">
      <i [class]="navItem.icon"></i>
      <span>{{ navItem.name }}</span>
    </a>
  </li>
  <li *ngIf="(user && user.username !== '') && hasSubscriptions" class="nav-item channel-sidebar-list">
    <h6>MIS SUSCRIPCIONES</h6>
    <ul>
      <li *ngFor="let subscription of subscriptionItems">
        <a [routerLink]="'/canal/' + subscription.channelName">
          <img class="img-fluid" alt="" src="assets/img/s1.png"> {{subscription.channelName}}
        </a>
      </li>
    </ul>
  </li>
</ul>
