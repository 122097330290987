<!-- <body class="login-main-body"> -->
<section class="login-main-wrapper">
  <div class="container-fluid pl-0 pr-0">
    <div class="row no-gutters">
      <div class="col-md-5 p-5 bg-white full-height">
        <div class="login-main-left">
          <div class="text-center mb-5 login-main-left-header pt-4">
            <img src="assets/img/favicon.png" class="img-fluid" alt="LOGO">
            <h5 class="mt-3 mb-3">Bienvenidos a GDON</h5>
            <p>Por favor Ingresa tus credenciales <br> para acceder y disfrutar de todas las ventajas.</p>
          </div>
          <form [formGroup]="loginForm" (ngSubmit)="onSubmit()">
            <div class="form-group">
              <label>Correo electrónico</label>
              <input
                type="email"
                [ngClass]="{ 'is-invalid': submitted && f.email.errors }"
                name="email"
                formControlName="email"
                class="form-control"
                placeholder="Ingrese el correo electrónico">
              <div *ngIf="submitted && f.email.errors" class="invalid-feedback">
                <div *ngIf="f.email.errors.required">Debe incluir un email</div>
              </div>
            </div>
            <div class="form-group">
              <label>Contraseña</label>
              <input
                type="password"
                [ngClass]="{ 'is-invalid': submitted && f.password.errors }"
                name="password"
                formControlName="password"
                class="form-control"
                placeholder="Contraseña">
              <div *ngIf="submitted && f.password.errors" class="invalid-feedback">
                <div *ngIf="f.password.errors.required">Debe escribir su contraseña</div>
              </div>
            </div>
            <div class="input-group mb-3">
              <ngx-recaptcha2 [siteKey]="siteKey()"
                              (reset)="handleReset()"
                              (expire)="handleExpire()"
                              (load)="handleLoad()"
                              (success)="handleSuccess($event)"
                              [useGlobalDomain]="false"
                              [size]="size"
                              [hl]="lang"
                              [theme]="theme"
                              [type]="type"
                              formControlName="recaptcha">
              </ngx-recaptcha2>
              <div *ngIf="submitted && f.recaptcha.errors" class="invalid-feedback">
                <div *ngIf="f.recaptcha.errors.required">No coincide las claves del captcha</div>
              </div>
            </div>
            <div class="mt-4">
              <div class="row">
                <div class="col-12">
                  <button type="submit" class="btn btn-outline-primary btn-block btn-lg">Entrar</button>
                </div>
              </div>
            </div>
          </form>
          <div class="text-center mt-5">
            <p class="light-gray">No tienes una cuenta? <a routerLink="/registro">Registrate</a></p>
          </div>
        </div>
      </div>
      <div class="col-md-7">
        <app-login-carousel></app-login-carousel>
      </div>
    </div>
  </div>
</section>

