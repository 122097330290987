<div class="main">
  <app-nav [loggedIn]="loggedIn" [user]="usuario"></app-nav>
  <app-footer></app-footer>
  <div id="wrapper">
    <app-sidebar [loggedIn]="loggedIn" [user]="usuario"></app-sidebar>
    <div id="content-wrapper">
      <div class="container-fluid upload-details">
        <div class="row">
          <div class="col-lg-12">
            <div class="main-title">
              <h6>Detalles del Video</h6>
            </div>
          </div>
          <div class="col-lg-2">
            <div class="imgplace">
              <video id="videouploaded" [src]="urlvideo" height="94"></video>
            </div>
          </div>
          <div class="col-lg-10">
            <div class="osahan-title">{{title.replace('.png', '')}}</div>
            <div class="osahan-size">{{(sizevideo/1000000).toFixed(2)}} MB . {{(duration/60).toFixed(2)}} min </div>
            <div class="osahan-progress">
              <div class="progress">
                <div class="progress-bar progress-bar-striped progress-bar-animated" role="progressbar" aria-valuenow="100" aria-valuemin="0" aria-valuemax="100" style="width: 100%"></div>
              </div>
              <div class="osahan-close">
                <a href="#"><i class="fas fa-times-circle"></i></a>
              </div>
            </div>
            <div class="osahan-desc">Tu archivo se ha cargado correctamente</div>
          </div>
        </div>
        <hr>
        <div class="row">
          <div class="col-lg-12">
            <form [formGroup]="videoForm" (ngSubmit)="onSubmit()">
              <div class="osahan-form">
                <div class="row">
                  <div class="col-lg-8">
                    <div class="form-group">
                      <label for="e1">Titulo</label>
                      <input
                        type="text"
                        placeholder="Nombre del Video"
                        name="namevideo"
                        formControlName="title"
                        [disabled]="submitted"
                        [value]="title"
                        id="e1" class="form-control"
                        [ngClass]="{ 'is-invalid': submitted && f.title.errors }"
                      >
                      <div *ngIf="submitted && f.title.errors" class="invalid-feedback">
                        <div *ngIf="f.title.errors.required">Debe incluir un usuario</div>
                      </div>
                    </div>
                  </div>
                  <div class="col-lg-4 col-xs-4 col-4">
                    <div class="form-group">
                      <label for="e8">Imagen/Captura del video</label>
                      <input
                        type="text"
                        placeholder="Nathan Drake,"
                        id="e13"
                        class="form-control"
                        formControlName="personajes"
                        [ngClass]="{ 'is-invalid': submitted && f.personajes.errors }">
                      <div *ngIf="submitted && f.personajes.errors" class="invalid-feedback">
                        <div *ngIf="f.personajes.errors.required">Este campo es obligatorio</div>
                      </div>
                    </div>
                  </div>
                  <div class="col-lg-12">
                    <div class="form-group">
                      <label for="e2">Descripción</label>
                      <textarea
                        rows="3"
                        id="e2"
                        name="e2"
                        formControlName="description"
                        [ngClass]="{ 'is-invalid': submitted && f.description.errors }"
                        class="form-control">Descripción</textarea>
                      <div *ngIf="submitted && f.description.errors" class="invalid-feedback">
                        <div *ngIf="f.description.errors.required">Debe incluir una descripción para este video</div>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="row">
                  <div class="col-lg-3">
                    <div class="form-group">
                      <label for="e3">Orientación</label>
                      <select
                        formControlName="orientation"
                        id="e3"
                        class="custom-select"
                        [ngClass]="{ 'is-invalid': submitted && f.orientation.errors }">
                        <option *ngFor="let option of orientationOptions" value="{{option.value}}">{{ option.text }}</option>
                      </select>
                      <div *ngIf="submitted && f.orientation.errors" class="invalid-feedback">
                        <div *ngIf="f.orientation.errors.required">Por favor debe seleccionar la orientación</div>
                      </div>
                    </div>
                  </div>
                  <div class="col-lg-3">
                    <div class="form-group">
                      <label for="e4">Tipo</label>
                      <select
                        formControlName="public"
                        id="e4"
                        class="custom-select"
                        [ngClass]="{ 'is-invalid': submitted && f.public.errors }">
                        <option *ngFor="let option of typeOptions" [ngValue]="option">{{ option }}</option>
                      </select>
                      <div *ngIf="submitted && f.public.errors" class="invalid-feedback">
                        <div *ngIf="f.public.errors.required">Este campo es obligatorio</div>
                      </div>
                    </div>
                  </div>
                  <div class="col-lg-3">
                    <div class="form-group">
                      <label for="e5">Monetización</label>
                      <select
                        formControlName="monetization"
                        id="e5"
                        class="custom-select"
                        [ngClass]="{ 'is-invalid': submitted && f.monetization.errors }">
                        <option *ngFor="let option of monetizationOptions" [ngValue]="option">{{ option }}</option>
                      </select>
                      <div *ngIf="submitted && f.monetization.errors" class="invalid-feedback">
                        <div *ngIf="f.monetization.errors.required">Este campo es obligatorio</div>
                      </div>
                    </div>
                  </div>
                  <div class="col-lg-3">
                    <div class="form-group">
                      <label for="e6">Licencia</label>
                      <select
                        formControlName="license"
                        id="e6"
                        class="custom-select"
                        [ngClass]="{ 'is-invalid': submitted && f.license.errors }">
                        <option *ngFor="let option of licenseOptions" [ngValue]="option">{{ option }}</option>
                      </select>
                      <div *ngIf="submitted && f.license.errors" class="invalid-feedback">
                        <div *ngIf="f.license.errors.required">Este campo es obligatorio</div>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="row">
                  <div class="col-lg-5">
                    <div class="form-group">
                      <label for="e7">Tags (Max. 10 Etiquetas)</label>
                      <input
                        type="text"
                        placeholder="Escribe las etiquetas de tu archivo"
                        id="e7"
                        class="form-control"
                        formControlName="tags"
                        [ngClass]="{ 'is-invalid': submitted && f.tags.errors }">
                      <div *ngIf="submitted && f.tags.errors" class="invalid-feedback">
                        <div *ngIf="f.tags.errors.required">Este campo es obligatorio</div>
                      </div>
                    </div>
                  </div>
                  <div class="col-lg-4">
                    <div class="form-group">
                      <label for="e8">Personajes</label>
                      <input
                        type="text"
                        placeholder="Nathan Drake,"
                        id="e8"
                        class="form-control"
                        formControlName="personajes"
                        [ngClass]="{ 'is-invalid': submitted && f.personajes.errors }">
                      <div *ngIf="submitted && f.personajes.errors" class="invalid-feedback">
                        <div *ngIf="f.personajes.errors.required">Este campo es obligatorio</div>
                      </div>
                    </div>
                  </div>
                  <div class="col-lg-3">
                    <div class="form-group">
                      <label for="e9">Lenguaje</label>
                      <select
                        formControlName="language"
                        id="e9"
                        class="custom-select"
                        [ngClass]="{ 'is-invalid': submitted && f.language.errors }">
                        <option *ngFor="let option of languageOptions" [ngValue]="option">{{ option }}</option>
                      </select>
                      <div *ngIf="submitted && f.language.errors" class="invalid-feedback">
                        <div *ngIf="f.language.errors.required">Este campo es obligatorio</div>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="row ">
                  <div class="col-lg-12">
                    <div class="main-title">
                      <h6>Categorias ( puedes seleccionar hasta 7 categorias )</h6>
                    </div>
                  </div>
                </div>
                <div class="row category-checkbox">

                  <div class="col-lg-2 col-xs-6 col-4">
                    <div class="custom-control custom-checkbox">
                      <input type="checkbox" formControlName="category" value="Abaft" class="custom-control-input" id="customCheck1">
                      <label class="custom-control-label" for="customCheck1">Abaft</label>
                    </div>
                    <div class="custom-control custom-checkbox">
                      <input type="checkbox" formControlName="category" value="Brick" class="custom-control-input" id="customCheck2">
                      <label class="custom-control-label" for="customCheck2">Brick</label>
                    </div>
                    <div class="custom-control custom-checkbox">
                      <input type="checkbox" formControlName="category" value="Purpose" class="custom-control-input" id="customCheck3">
                      <label class="custom-control-label" for="customCheck3">Purpose</label>
                    </div>
                  </div>

                  <div class="col-lg-2 col-xs-6 col-4">
                    <div class="custom-control custom-checkbox">
                      <input type="checkbox" formControlName="category" value="Cemetery" class="custom-control-input" id="zcustomCheck1">
                      <label class="custom-control-label" for="zcustomCheck1">Cemetery</label>
                    </div>
                    <div class="custom-control custom-checkbox">
                      <input type="checkbox" formControlName="category" value="Trouble" class="custom-control-input" id="zcustomCheck2">
                      <label class="custom-control-label" for="zcustomCheck2">Trouble</label>
                    </div>
                    <div class="custom-control custom-checkbox">
                      <input type="checkbox" formControlName="category" value="Pin" class="custom-control-input" id="zcustomCheck3">
                      <label class="custom-control-label" for="zcustomCheck3">Pin</label>
                    </div>
                  </div>

                  <div class="col-lg-2 col-xs-6 col-4">
                    <div class="custom-control custom-checkbox">
                      <input type="checkbox" formControlName="category" value="Scissors" class="custom-control-input" id="czcustomCheck1">
                      <label class="custom-control-label" for="czcustomCheck1">Scissors</label>
                    </div>
                    <div class="custom-control custom-checkbox">
                      <input type="checkbox" formControlName="category" value="Stitch" class="custom-control-input" id="czcustomCheck2">
                      <label class="custom-control-label" for="czcustomCheck2">Stitch</label>
                    </div>
                    <div class="custom-control custom-checkbox">
                      <input type="checkbox" formControlName="category" value="Agonizing" class="custom-control-input" id="czcustomCheck3">
                      <label class="custom-control-label" for="czcustomCheck3">Agonizing</label>
                    </div>
                  </div>
                  <div class="col-lg-2 col-xs-6 col-4">
                    <div class="custom-control custom-checkbox">
                      <input type="checkbox" formControlName="category" value="Shallow" class="custom-control-input" id="customCheck4">
                      <label class="custom-control-label" for="customCheck4">Shallow</label>
                    </div>
                    <div class="custom-control custom-checkbox">
                      <input type="checkbox" formControlName="category" value="Spray" class="custom-control-input" id="customCheck5">
                      <label class="custom-control-label" for="customCheck5">Spray</label>
                    </div>
                  </div>
                  <div class="col-lg-2 col-xs-6 col-4">
                    <div class="custom-control custom-checkbox">
                      <input type="checkbox" formControlName="category" value="Fall" class="custom-control-input" id="zcustomCheck4">
                      <label class="custom-control-label" for="zcustomCheck4">Fall</label>
                    </div>
                    <div class="custom-control custom-checkbox">
                      <input type="checkbox" formControlName="category" value="Leg" class="custom-control-input" id="zcustomCheck5">
                      <label class="custom-control-label" for="zcustomCheck5">Leg</label>
                    </div>
                  </div>
                  <div class="col-lg-2 col-xs-6 col-4">
                    <div class="custom-control custom-checkbox">
                      <input type="checkbox" formControlName="category" value="Rescue" class="custom-control-input" id="czcustomCheck4">
                      <label class="custom-control-label" for="czcustomCheck4">Rescue</label>
                    </div>
                    <div class="custom-control custom-checkbox">
                      <input type="checkbox" formControlName="category" value="Quiet" class="custom-control-input" id="czcustomCheck5">
                      <label class="custom-control-label" for="czcustomCheck5">Quiet</label>
                    </div>
                  </div>
                </div>
                <div class="row category-checkbox">
                  <div class="col-lg-4 col-xs-4 col-4">
                    <div class="osahan-area text-center mt-3" style="text-align:center;margin:0 auto;">
                      <ngx-recaptcha2 [siteKey]="siteKey()"
                                      (reset)="handleReset()"
                                      (expire)="handleExpire()"
                                      (load)="handleLoad()"
                                      (success)="handleSuccess($event)"
                                      [useGlobalDomain]="false"
                                      [size]="size"
                                      [hl]="lang"
                                      [theme]="theme"
                                      [type]="type"
                                      [ngClass]="{ 'is-invalid': submitted && f.recaptcha.errors }"
                                      formControlName="recaptcha"
                                      style="margin:0 auto;text-align: center;">
                      </ngx-recaptcha2>
                      <div *ngIf="submitted && f.recaptcha.errors" class="invalid-feedback">
                        <div *ngIf="f.recaptcha.errors.required">Por favor, resuelva el captcha para poder avanzar</div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div class="osahan-area text-center mt-3">
                <button [disabled]="loading" class="btn btn-outline-primary">Guardar Cambios</button>
              </div>
            </form>
            <hr>
            <div class="terms text-center">
              <p class="mb-0">There are many variations of passages of Lorem Ipsum available, but the majority <a href="#">Terms of Service</a> and <a href="#">Community Guidelines</a>.</p>
              <p class="hidden-xs mb-0">Ipsum is therefore always free from repetition, injected humour, or non</p>
            </div>
          </div>
        </div>
      </div>
      <app-subfooter></app-subfooter>
    </div>
  </div>
</div>
