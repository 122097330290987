<div class="main">
  <div class="modal fade" id="videoModal" tabindex="-1" role="dialog" aria-labelledby="exampleModalLabel" aria-hidden="true">
    <div class="modal-dialog modal-lg modal-dialog-centered" role="document">
      <div class="modal-content">
        <div class="modal-header">
          <h5 class="modal-title">{{title}}</h5>
          <button class="close" type="button" data-dismiss="modal" aria-label="Close">
            <span aria-hidden="true">×</span>
          </button>
        </div>
        <div class="modal-body p-0 mb-n2">
          <app-repro [videoId]="videoId"></app-repro>
        </div>
      </div>
    </div>
  </div>

  <app-nav [loggedIn]="loggedIn" [user]="usuario"></app-nav>
  <app-footer></app-footer>
  <div id="wrapper">
    <app-sidebar [loggedIn]="loggedIn" [user]="usuario"></app-sidebar>
    <div id="content-wrapper">
      <div class="container-fluid pb-0">
        <div class="video-block-right-list section-padding">
          <div class="row mb-4">
            <div class="col-md-8">
              <div class="single-video">
                <button class="border-none bg-transparent p-0" style="outline: none; box-shadow: none;">
                  <div class="video-card bg-transparent shadow-none" data-toggle="modal" data-target="#videoModal">
                    <a class="play-icon ml-3" [style.width]="width" style="height: 98.5%"><i class="fas fa-play-circle"></i></a>
                    <!-- <app-repro [videoId]="videoId"></app-repro> -->
                    <video (durationchange)="setWidth($event.target.clientWidth)" width="100%" [src]="videoUrl" [autoplay]="true" [controls]="true"></video>
                  </div>
                </button>
              </div>
            </div>
            <div class="col-md-4">
              <div class="video-slider-right-list">
                <div class="video-card video-card-list">
                  <div class="video-card-image">
                    <a class="play-icon" href="#"><i class="fas fa-play-circle"></i></a>
                    <a href="#">
                      <img class="img-fluid" src="assets/img/v1.png" alt="">
                    </a>
                    <div class="time">3:50</div>
                  </div>
                  <div class="video-card-body">
                    <div class="btn-group float-right right-action">
                      <a href="#" class="right-action-link text-gray" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                        <i class="fa fa-ellipsis-v" aria-hidden="true"></i>
                      </a>
                      <div class="dropdown-menu dropdown-menu-right">
                        <a class="dropdown-item" href="#"><i class="fas fa-fw fa-star"></i> &nbsp; Top Rated</a>
                        <a class="dropdown-item" href="#"><i class="fas fa-fw fa-signal"></i> &nbsp; Viewed</a>
                        <a class="dropdown-item" href="#"><i class="fas fa-fw fa-times-circle"></i> &nbsp; Close</a>
                      </div>
                    </div>
                    <div class="video-title">
                      <a href="#">Minutos de Salud</a>
                    </div>
                    <div class="video-page text-success">
                      Salud <a title="" data-placement="top" data-toggle="tooltip" href="#" data-original-title="Verified"><i class="fas fa-check-circle text-success"></i></a>
                    </div>
                    <div class="video-view">
                      1.8M reporduciones &nbsp;<i class="fas fa-calendar-alt"></i> Hace 11 meses
                    </div>
                  </div>
                </div>
                <div class="video-card video-card-list active">
                  <div class="video-card-image">
                    <a class="play-icon" href="#"><i class="fas fa-play-circle"></i></a>
                    <a href="#"><img class="img-fluid" src="assets/img/v2.png" alt=""></a>
                    <div class="time">3:50</div>
                  </div>
                  <div class="video-card-body">
                    <div class="btn-group float-right right-action">
                      <a href="#" class="right-action-link text-gray" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                        <i class="fa fa-ellipsis-v" aria-hidden="true"></i>
                      </a>
                      <div class="dropdown-menu dropdown-menu-right">
                        <a class="dropdown-item" href="#"><i class="fas fa-fw fa-star"></i> &nbsp; Top Rated</a>
                        <a class="dropdown-item" href="#"><i class="fas fa-fw fa-signal"></i> &nbsp; Viewed</a>
                        <a class="dropdown-item" href="#"><i class="fas fa-fw fa-times-circle"></i> &nbsp; Close</a>
                      </div>
                    </div>
                    <div class="video-title">
                      <a href="#">Congreso de alimentaci&oacute;n basado en plantas</a>
                    </div>
                    <div class="video-page text-success">
                      Nutrici&oacute;n y dietetica <a title="" data-placement="top" data-toggle="tooltip" href="#" data-original-title="Verified"><i class="fas fa-check-circle text-success"></i></a>
                    </div>
                    <div class="video-view">
                      1.8M reproduciones &nbsp;<i class="fas fa-calendar-alt"></i> hace 10 meses
                    </div>
                  </div>
                </div>
                <div class="video-card video-card-list">
                  <div class="video-card-image">
                    <a class="play-icon" href="#"><i class="fas fa-play-circle"></i></a>
                    <a href="#">
                      <img class="img-fluid" src="assets/img/v3.png" alt="">
                    </a>
                    <div class="time">3:50</div>
                  </div>
                  <div class="video-card-body">
                    <div class="btn-group float-right right-action">
                      <a href="#" class="right-action-link text-gray" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                        <i class="fa fa-ellipsis-v" aria-hidden="true"></i>
                      </a>
                      <div class="dropdown-menu dropdown-menu-right">
                        <a class="dropdown-item" href="#"><i class="fas fa-fw fa-star"></i> &nbsp; Top Rated</a>
                        <a class="dropdown-item" href="#"><i class="fas fa-fw fa-signal"></i> &nbsp; Viewed</a>
                        <a class="dropdown-item" href="#"><i class="fas fa-fw fa-times-circle"></i> &nbsp; Close</a>
                      </div>
                    </div>
                    <div class="video-title">
                      <a href="#">Seminario de salud - Dieta Plana</a>
                    </div>
                    <div class="video-page text-success">
                      Education <a title="" data-placement="top" data-toggle="tooltip" href="#" data-original-title="Verified"><i class="fas fa-check-circle text-success"></i></a>
                    </div>
                    <div class="video-view">
                      1.8M reproducciones &nbsp;<i class="fas fa-calendar-alt"></i> Hace 24 dias
                    </div>
                  </div>
                </div>
                <div class="video-card video-card-list">
                  <div class="video-card-image">
                    <a class="play-icon" href="#"><i class="fas fa-play-circle"></i></a>
                    <a href="#">
                      <img class="img-fluid" src="assets/img/v4.png" alt="">
                    </a>
                    <div class="time">3:50</div>
                  </div>
                  <div class="video-card-body">
                    <div class="btn-group float-right right-action">
                      <a href="#" class="right-action-link text-gray" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                        <i class="fa fa-ellipsis-v" aria-hidden="true"></i>
                      </a>
                      <div class="dropdown-menu dropdown-menu-right">
                        <a class="dropdown-item" href="#"><i class="fas fa-fw fa-star"></i> &nbsp; Top Rated</a>
                        <a class="dropdown-item" href="#"><i class="fas fa-fw fa-signal"></i> &nbsp; Viewed</a>
                        <a class="dropdown-item" href="#"><i class="fas fa-fw fa-times-circle"></i> &nbsp; Close</a>
                      </div>
                    </div>
                    <div class="video-title">
                      <a href="#">La lengua de cana&aacute;n - Pilares de nuestra fe</a>
                    </div>
                    <div class="video-page text-success">
                      M&uacute;sica <a title="" data-placement="top" data-toggle="tooltip" href="#" data-original-title="Verified"><i class="fas fa-check-circle text-success"></i></a>
                    </div>
                    <div class="video-view">
                      1.8M reproducciones &nbsp;<i class="fas fa-calendar-alt"></i> hace 30 d&iacute;as
                    </div>
                  </div>
                </div>
                <div class="video-card video-card-list">
                  <div class="video-card-image">
                    <a class="play-icon" href="#"><i class="fas fa-play-circle"></i></a>
                    <a href="#"><img class="img-fluid" src="assets/img/v5.png" alt=""></a>
                    <div class="time">3:50</div>
                  </div>
                  <div class="video-card-body">
                    <div class="btn-group float-right right-action">
                      <a href="#" class="right-action-link text-gray" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                        <i class="fa fa-ellipsis-v" aria-hidden="true"></i>
                      </a>
                      <div class="dropdown-menu dropdown-menu-right">
                        <a class="dropdown-item" href="#"><i class="fas fa-fw fa-star"></i> &nbsp; Top Rated</a>
                        <a class="dropdown-item" href="#"><i class="fas fa-fw fa-signal"></i> &nbsp; Viewed</a>
                        <a class="dropdown-item" href="#"><i class="fas fa-fw fa-times-circle"></i> &nbsp; Close</a>
                      </div>
                    </div>
                    <div class="video-title">
                      <a href="#">Exercitation ullamco laboris nisi ut.</a>
                    </div>
                    <div class="video-page text-success">
                      Education <a title="" data-placement="top" data-toggle="tooltip" href="#" data-original-title="Verified"><i class="fas fa-check-circle text-success"></i></a>
                    </div>
                    <div class="video-view">
                      1.8M views &nbsp;<i class="fas fa-calendar-alt"></i> 11 Months ago
                    </div>
                  </div>
                </div>
                <div class="video-card video-card-list">
                  <div class="video-card-image">
                    <a class="play-icon" href="#"><i class="fas fa-play-circle"></i></a>
                    <a href="#"><img class="img-fluid" src="assets/img/v6.png" alt=""></a>
                    <div class="time">3:50</div>
                  </div>
                  <div class="video-card-body">
                    <div class="btn-group float-right right-action">
                      <a href="#" class="right-action-link text-gray" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                        <i class="fa fa-ellipsis-v" aria-hidden="true"></i>
                      </a>
                      <div class="dropdown-menu dropdown-menu-right">
                        <a class="dropdown-item" href="#"><i class="fas fa-fw fa-star"></i> &nbsp; Top Rated</a>
                        <a class="dropdown-item" href="#"><i class="fas fa-fw fa-signal"></i> &nbsp; Viewed</a>
                        <a class="dropdown-item" href="#"><i class="fas fa-fw fa-times-circle"></i> &nbsp; Close</a>
                      </div>
                    </div>
                    <div class="video-title">
                      <a href="#">There are many variations of passages of Lorem</a>
                    </div>
                    <div class="video-page text-success">
                      Education <a title="" data-placement="top" data-toggle="tooltip" href="#" data-original-title="Verified"><i class="fas fa-check-circle text-success"></i></a>
                    </div>
                    <div class="video-view">
                      1.8M views &nbsp;<i class="fas fa-calendar-alt"></i> 11 Months ago
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="video-block section-padding">
          <div class="row">
            <div class="col-md-8">
              <div class="single-video-left">
                <div class="single-video-title box mb-3">
                  <div class="row justify-content-end ">
                    <div class="col col-lg-7 col-xl-8">
                      <h2><a href="#">{{ title }}</a></h2>
                    </div>
                    <div class="col-1"></div>
                    <div class="col">
                      <!-- LIKE BUTTON -->
                      <button (click)="checkLikeStatus('like')" [class]="userLikesVideo ? 'like negativeLikeButton' : 'like likeButton'">
                        <i class="fas fa-thumbs-up"></i> {{ likes }}
                      </button>
                      &nbsp;
                      <!-- DISLIKE BUTTON -->
                      <button (click)="checkLikeStatus('dislike')" [class]="userDislikesVideo ? 'like negativeLikeButton' : 'like likeButton'">
                        <i class="fas fa-thumbs-down"></i> {{ dislikes }}
                      </button>
                    </div>
                  </div>
                  <div class="row ml-1">
                    <p class="mb-0">
                      <i class="fas fa-eye"></i>&nbsp;
                      {{views}} reproducciones • {{ displayDate }}
                    </p>
                  </div>
                </div>
                <div class="single-video-author box mb-3">
                  <!-- SUBSCRIBE -->
                  <div *ngIf="!subscribed && usuario.username !== channelName" class="float-right">
                    <button (click)="subscribe()" class="btn btn-danger" type="button">Suscribirme</button>
                  </div>
                  <!-- UNSUBSCRIBE -->
                  <div *ngIf="subscribed && usuario.username !== channelName" class="float-right">
                    <button (click)="unsubscribe()" class="btn btn-danger" type="button">Anular subscripción</button>
                    <button (click)="updateNotification()" [class]="notification ? 'btn btn btn-danger' : 'btn btn btn-outline-danger'" type="button"><i class="fas fa-bell"></i></button>
                  </div>
                  <img class="img-fluid" src="assets/img/s4.png" alt="">
                  <p><a [routerLink]="'/canal/' + channelName"><strong>{{ channelName }}&nbsp;</strong></a> <span title="" data-placement="top" data-toggle="tooltip" data-original-title="Verified"><i class="fas fa-check-circle text-success"></i></span></p>
                  <small>{{ subscriptors > 0 ? subscriptors + ' Suscriptores' : 'Sin subscriptores' }}</small>
                </div>
                <div class="single-video-info-content box mb-3">
                  <h6>Categoría:</h6>
                  <p>{{ category }}</p>
                  <h6>Descripción:</h6>
                  <p [innerHTML]="description"></p>
                  <h6 *ngIf="tags.length > 0">Tags:</h6>
                  <p class="tags mb-0">
                    <span *ngFor="let tag of tags"><a href="">{{ tag }}</a></span>
                  </p>
                </div>
                <div class="box mb-3 single-video-comment-tabs">
                  <ul class="nav nav-tabs" role="tablist">
                    <li class="nav-item">
                      <a class="nav-link active" id="retro-comments-tab" data-toggle="tab" href="#retro-comments" role="tab" aria-controls="retro" aria-selected="false">vidoe Comments</a>
                    </li>
                    <li class="nav-item">
                      <a class="nav-link" id="disqus-comments-tab" data-toggle="tab" href="#disqus-comments" role="tab" aria-controls="disqus" aria-selected="true">Disqus Comments</a>
                    </li>
                    <li class="nav-item">
                      <a class="nav-link" id="facebook-comments-tab" data-toggle="tab" href="#facebook-comments" role="tab" aria-controls="facebook" aria-selected="false">Facebook Comments</a>
                    </li>
                  </ul>
                  <div class="tab-content">
                    <div class="tab-pane fade" id="disqus-comments" role="tabpanel" aria-labelledby="disqus-comments-tab">
                      <h1>Soon...</h1>
                    </div>
                    <div class="tab-pane fade" id="facebook-comments" role="tabpanel" aria-labelledby="facebook-comments-tab">
                      <h1>Soon...</h1>
                    </div>
                    <div class="tab-pane fade show active" id="retro-comments" role="tabpanel" aria-labelledby="retro-comments-tab">
                      <div class="reviews-members pt-0">
                        <div class="media">
                          <a href="#"><img class="mr-3" src="assets/img/s1.png" alt="Generic placeholder image"></a>
                          <div class="media-body">
                            <div class="form-members-body">
                              <textarea
                                (focus)="checkUser()"
                                rows="1"
                                placeholder="Agrega un comentario público..." class="form-control"
                              ></textarea>
                            </div>
                            <div class="form-members-footer text-right mt-2">
                              <b class="float-left">12,725 Comments
                              </b>
                              <div *ngIf="comment">
                                <button class="btn btn-outline-danger btn-sm" (click)="comment = false" type="button">CANCEL</button>
                                <button class="btn btn-danger btn-sm" (click)="createComment()" type="button">COMMENT</button>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div *ngFor="let comment of comments" class="reviews-members">
                        <div class="media">
                          <a href="#"><img class="mr-3" src="assets/img/s2.png" alt="Generic placeholder image"></a>
                          <div class="media-body">
                            <div class="reviews-members-header">
                              <h6 class="mb-1"><a class="text-black" href="#"> {{ comment.user.username}} </a> <small class="text-gray">hace {{comment.createdAt}}</small></h6>
                            </div>
                            <div class="reviews-members-body">
                              <p [innerHTML]="comment.text"></p>
                            </div>
                            <div class="reviews-members-footer">
                              <!-- COMMENTS LIKE BUTTON -->
                              <button (click)="checkCommentLikeStatus(comment, 'like')" [class]="comment.userLikes ? 'like negativeLikeButton' : 'like likeButton'">
                                <i class="fas fa-thumbs-up"></i> {{ comment.likes }}
                              </button>
                              &nbsp;
                              <!-- COMMENTS DISLIKE BUTTON -->
                              <button (click)="checkCommentLikeStatus(comment, 'dislike')" [class]="comment.userDislikes ? 'like negativeLikeButton' : 'like likeButton'">
                                <i class="fas fa-thumbs-down"></i> {{ comment.dislikes }}
                              </button>
                              <span dir="rtl" class="total-like-user-main ml-2">
                                  <a href="#" title="" data-placement="top" data-toggle="tooltip" data-original-title="Gurdeep Osahan"><img class="total-like-user" src="assets/img/s1.png" alt="Generic placeholder image"></a>
                                  <a href="#" title="" data-placement="top" data-toggle="tooltip" data-original-title="Gurdeep Osahan"><img class="total-like-user" src="assets/img/s2.png" alt="Generic placeholder image"></a>
                                  <a href="#" title="" data-placement="top" data-toggle="tooltip" data-original-title="Gurdeep Osahan"><img class="total-like-user" src="assets/img/s3.png" alt="Generic placeholder image"></a>
                                  <a href="#" title="" data-placement="top" data-toggle="tooltip" data-original-title="Gurdeep Osahan"><img class="total-like-user" src="assets/img/s4.png" alt="Generic placeholder image"></a>
                              </span>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div class="col-md-4">
              <div class="single-video-right">
                <div class="row">
                  <div class="col-md-12">
                    <div class="adblock">
                      <div class="img">
                        Reclama totalmente gratis<br>
                        EL CONFLICTO DE LOS SIGLOS<br/>
                        click aqu&iacute;
                      </div>
                    </div>
                    <div class="main-title">
                      <div class="btn-group float-right right-action">
                        <a href="#" class="right-action-link text-gray" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                          Sort by <i class="fa fa-caret-down" aria-hidden="true"></i>
                        </a>
                        <div class="dropdown-menu dropdown-menu-right">
                          <a class="dropdown-item" href="#"><i class="fas fa-fw fa-star"></i> &nbsp; Top Rated</a>
                          <a class="dropdown-item" href="#"><i class="fas fa-fw fa-signal"></i> &nbsp; Viewed</a>
                          <a class="dropdown-item" href="#"><i class="fas fa-fw fa-times-circle"></i> &nbsp; Close</a>
                        </div>
                      </div>
                      <h6>Up Next</h6>
                    </div>
                  </div>
                  <div class="col-md-12">
                    <div class="video-card video-card-list">
                      <div class="video-card-image">
                        <a class="play-icon" href="#"><i class="fas fa-play-circle"></i></a>
                        <a href="#"><img class="img-fluid" src="assets/img/v1.png" alt=""></a>
                        <div class="time">3:50</div>
                      </div>
                      <div class="video-card-body">
                        <div class="btn-group float-right right-action">
                          <a href="#" class="right-action-link text-gray" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                            <i class="fa fa-ellipsis-v" aria-hidden="true"></i>
                          </a>
                          <div class="dropdown-menu dropdown-menu-right">
                            <a class="dropdown-item" href="#"><i class="fas fa-fw fa-star"></i> &nbsp; Top Rated</a>
                            <a class="dropdown-item" href="#"><i class="fas fa-fw fa-signal"></i> &nbsp; Viewed</a>
                            <a class="dropdown-item" href="#"><i class="fas fa-fw fa-times-circle"></i> &nbsp; Close</a>
                          </div>
                        </div>
                        <div class="video-title">
                          <a href="#">Here are many variati of passages of Lorem</a>
                        </div>
                        <div class="video-page text-success">
                          Education <a title="" data-placement="top" data-toggle="tooltip" href="#" data-original-title="Verified"><i class="fas fa-check-circle text-success"></i></a>
                        </div>
                        <div class="video-view">
                          1.8M views &nbsp;<i class="fas fa-calendar-alt"></i> 11 Months ago
                        </div>
                      </div>
                    </div>
                    <div class="video-card video-card-list">
                      <div class="video-card-image">
                        <a class="play-icon" href="#"><i class="fas fa-play-circle"></i></a>
                        <a href="#"><img class="img-fluid" src="assets/img/v2.png" alt=""></a>
                        <div class="time">3:50</div>
                      </div>
                      <div class="video-card-body">
                        <div class="btn-group float-right right-action">
                          <a href="#" class="right-action-link text-gray" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                            <i class="fa fa-ellipsis-v" aria-hidden="true"></i>
                          </a>
                          <div class="dropdown-menu dropdown-menu-right">
                            <a class="dropdown-item" href="#"><i class="fas fa-fw fa-star"></i> &nbsp; Top Rated</a>
                            <a class="dropdown-item" href="#"><i class="fas fa-fw fa-signal"></i> &nbsp; Viewed</a>
                            <a class="dropdown-item" href="#"><i class="fas fa-fw fa-times-circle"></i> &nbsp; Close</a>
                          </div>
                        </div>
                        <div class="video-title">
                          <a href="#">Duis aute irure dolor in reprehenderit in.</a>
                        </div>
                        <div class="video-page text-success">
                          Education <a title="" data-placement="top" data-toggle="tooltip" href="#" data-original-title="Verified"><i class="fas fa-check-circle text-success"></i></a>
                        </div>
                        <div class="video-view">
                          1.8M views &nbsp;<i class="fas fa-calendar-alt"></i> 11 Months ago
                        </div>
                      </div>
                    </div>
                    <div class="video-card video-card-list">
                      <div class="video-card-image">
                        <a class="play-icon" href="#"><i class="fas fa-play-circle"></i></a>
                        <a href="#"><img class="img-fluid" src="assets/img/v3.png" alt=""></a>
                        <div class="time">3:50</div>
                      </div>
                      <div class="video-card-body">
                        <div class="btn-group float-right right-action">
                          <a href="#" class="right-action-link text-gray" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                            <i class="fa fa-ellipsis-v" aria-hidden="true"></i>
                          </a>
                          <div class="dropdown-menu dropdown-menu-right">
                            <a class="dropdown-item" href="#"><i class="fas fa-fw fa-star"></i> &nbsp; Top Rated</a>
                            <a class="dropdown-item" href="#"><i class="fas fa-fw fa-signal"></i> &nbsp; Viewed</a>
                            <a class="dropdown-item" href="#"><i class="fas fa-fw fa-times-circle"></i> &nbsp; Close</a>
                          </div>
                        </div>
                        <div class="video-title">
                          <a href="#">Culpa qui officia deserunt mollit anim</a>
                        </div>
                        <div class="video-page text-success">
                          Education <a title="" data-placement="top" data-toggle="tooltip" href="#" data-original-title="Verified"><i class="fas fa-check-circle text-success"></i></a>
                        </div>
                        <div class="video-view">
                          1.8M views &nbsp;<i class="fas fa-calendar-alt"></i> 11 Months ago
                        </div>
                      </div>
                    </div>
                    <div class="video-card video-card-list">
                      <div class="video-card-image">
                        <a class="play-icon" href="#"><i class="fas fa-play-circle"></i></a>
                        <a href="#"><img class="img-fluid" src="assets/img/v4.png" alt=""></a>
                        <div class="time">3:50</div>
                      </div>
                      <div class="video-card-body">
                        <div class="btn-group float-right right-action">
                          <a href="#" class="right-action-link text-gray" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                            <i class="fa fa-ellipsis-v" aria-hidden="true"></i>
                          </a>
                          <div class="dropdown-menu dropdown-menu-right">
                            <a class="dropdown-item" href="#"><i class="fas fa-fw fa-star"></i> &nbsp; Top Rated</a>
                            <a class="dropdown-item" href="#"><i class="fas fa-fw fa-signal"></i> &nbsp; Viewed</a>
                            <a class="dropdown-item" href="#"><i class="fas fa-fw fa-times-circle"></i> &nbsp; Close</a>
                          </div>
                        </div>
                        <div class="video-title">
                          <a href="#">Deserunt mollit anim id est laborum.</a>
                        </div>
                        <div class="video-page text-success">
                          Education <a title="" data-placement="top" data-toggle="tooltip" href="#" data-original-title="Verified"><i class="fas fa-check-circle text-success"></i></a>
                        </div>
                        <div class="video-view">
                          1.8M views &nbsp;<i class="fas fa-calendar-alt"></i> 11 Months ago
                        </div>
                      </div>
                    </div>
                    <div class="video-card video-card-list">
                      <div class="video-card-image">
                        <a class="play-icon" href="#"><i class="fas fa-play-circle"></i></a>
                        <a href="#"><img class="img-fluid" src="assets/img/v5.png" alt=""></a>
                        <div class="time">3:50</div>
                      </div>
                      <div class="video-card-body">
                        <div class="btn-group float-right right-action">
                          <a href="#" class="right-action-link text-gray" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                            <i class="fa fa-ellipsis-v" aria-hidden="true"></i>
                          </a>
                          <div class="dropdown-menu dropdown-menu-right">
                            <a class="dropdown-item" href="#"><i class="fas fa-fw fa-star"></i> &nbsp; Top Rated</a>
                            <a class="dropdown-item" href="#"><i class="fas fa-fw fa-signal"></i> &nbsp; Viewed</a>
                            <a class="dropdown-item" href="#"><i class="fas fa-fw fa-times-circle"></i> &nbsp; Close</a>
                          </div>
                        </div>
                        <div class="video-title">
                          <a href="#">Exercitation ullamco laboris nisi ut.</a>
                        </div>
                        <div class="video-page text-success">
                          Education <a title="" data-placement="top" data-toggle="tooltip" href="#" data-original-title="Verified"><i class="fas fa-check-circle text-success"></i></a>
                        </div>
                        <div class="video-view">
                          1.8M views &nbsp;<i class="fas fa-calendar-alt"></i> 11 Months ago
                        </div>
                      </div>
                    </div>
                    <div class="video-card video-card-list">
                      <div class="video-card-image">
                        <a class="play-icon" href="#"><i class="fas fa-play-circle"></i></a>
                        <a href="#"><img class="img-fluid" src="assets/img/v6.png" alt=""></a>
                        <div class="time">3:50</div>
                      </div>
                      <div class="video-card-body">
                        <div class="btn-group float-right right-action">
                          <a href="#" class="right-action-link text-gray" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                            <i class="fa fa-ellipsis-v" aria-hidden="true"></i>
                          </a>
                          <div class="dropdown-menu dropdown-menu-right">
                            <a class="dropdown-item" href="#"><i class="fas fa-fw fa-star"></i> &nbsp; Top Rated</a>
                            <a class="dropdown-item" href="#"><i class="fas fa-fw fa-signal"></i> &nbsp; Viewed</a>
                            <a class="dropdown-item" href="#"><i class="fas fa-fw fa-times-circle"></i> &nbsp; Close</a>
                          </div>
                        </div>
                        <div class="video-title">
                          <a href="#">There are many variations of passages of Lorem</a>
                        </div>
                        <div class="video-page text-success">
                          Education <a title="" data-placement="top" data-toggle="tooltip" href="#" data-original-title="Verified"><i class="fas fa-check-circle text-success"></i></a>
                        </div>
                        <div class="video-view">
                          1.8M views &nbsp;<i class="fas fa-calendar-alt"></i> 11 Months ago
                        </div>
                      </div>
                    </div>
                    <div class="adblock mt-0">
                      <div class="img">
                        Google AdSense<br>
                        336 x 280
                      </div>
                    </div>
                    <div class="video-card video-card-list">
                      <div class="video-card-image">
                        <a class="play-icon" href="#"><i class="fas fa-play-circle"></i></a>
                        <a href="#"><img class="img-fluid" src="assets/img/v1.png" alt=""></a>
                        <div class="time">3:50</div>
                      </div>
                      <div class="video-card-body">
                        <div class="btn-group float-right right-action">
                          <a href="#" class="right-action-link text-gray" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                            <i class="fa fa-ellipsis-v" aria-hidden="true"></i>
                          </a>
                          <div class="dropdown-menu dropdown-menu-right">
                            <a class="dropdown-item" href="#"><i class="fas fa-fw fa-star"></i> &nbsp; Top Rated</a>
                            <a class="dropdown-item" href="#"><i class="fas fa-fw fa-signal"></i> &nbsp; Viewed</a>
                            <a class="dropdown-item" href="#"><i class="fas fa-fw fa-times-circle"></i> &nbsp; Close</a>
                          </div>
                        </div>
                        <div class="video-title">
                          <a href="#">Here are many variati of passages of Lorem</a>
                        </div>
                        <div class="video-page text-success">
                          Education <a title="" data-placement="top" data-toggle="tooltip" href="#" data-original-title="Verified"><i class="fas fa-check-circle text-success"></i></a>
                        </div>
                        <div class="video-view">
                          1.8M views &nbsp;<i class="fas fa-calendar-alt"></i> 11 Months ago
                        </div>
                      </div>
                    </div>
                    <div class="video-card video-card-list">
                      <div class="video-card-image">
                        <a class="play-icon" href="#"><i class="fas fa-play-circle"></i></a>
                        <a href="#"><img class="img-fluid" src="assets/img/v2.png" alt=""></a>
                        <div class="time">3:50</div>
                      </div>
                      <div class="video-card-body">
                        <div class="btn-group float-right right-action">
                          <a href="#" class="right-action-link text-gray" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                            <i class="fa fa-ellipsis-v" aria-hidden="true"></i>
                          </a>
                          <div class="dropdown-menu dropdown-menu-right">
                            <a class="dropdown-item" href="#"><i class="fas fa-fw fa-star"></i> &nbsp; Top Rated</a>
                            <a class="dropdown-item" href="#"><i class="fas fa-fw fa-signal"></i> &nbsp; Viewed</a>
                            <a class="dropdown-item" href="#"><i class="fas fa-fw fa-times-circle"></i> &nbsp; Close</a>
                          </div>
                        </div>
                        <div class="video-title">
                          <a href="#">Duis aute irure dolor in reprehenderit in.</a>
                        </div>
                        <div class="video-page text-success">
                          Education <a title="" data-placement="top" data-toggle="tooltip" href="#" data-original-title="Verified"><i class="fas fa-check-circle text-success"></i></a>
                        </div>
                        <div class="video-view">
                          1.8M views &nbsp;<i class="fas fa-calendar-alt"></i> 11 Months ago
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
