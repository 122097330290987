import { Component, OnInit } from '@angular/core';
import { OwlOptions } from 'ngx-owl-carousel-o';
import { Categorias } from '../../models/categorias';
@Component({
  selector: 'app-top-categories',
  templateUrl: './top-categories.component.html',
  styleUrls: ['./top-categories.component.css']
})
export class TopCategoriesComponent implements OnInit {
  customOptions: OwlOptions = {
    loop: true,
    autoplay: true,
    mouseDrag: true,
    touchDrag: true,
    pullDrag: true,
    dots: false,
    navSpeed: 300,
    navText: ['Atrás', 'Adelante'],
    items: 8,
    responsive: {
      0: {
        items: 8
      },
      400: {
        items: 2
      },
      740: {
        items: 4
      },
      940: {
        items: 5
      },
      1200: {
        items: 7
      }
    },
    nav: true
  };
  slideStore: Categorias[] = [
    {
      id: 1,
      src: 'assets/img/icons/cocina-vegana.png',
      categoryname: 'Cocina Vegana',
      categoryviews: 74853
    },
    {
      id: 2,
      src: 'assets/img/icons/sermones.jpg',
      categoryname: 'Sermones',
      categoryviews: 74853
    },
    {
      id: 3,
      src: 'assets/img/icons/campaigns.png',
      categoryname: 'Campañas',
      categoryviews: 74853
    },
    {
      id: 4,
      src: 'assets/img/icons/conferencias.png',
      categoryname: 'Conferencias',
      categoryviews: 74853
    },
    {
      id: 5,
      src: 'assets/img/icons/seminarios.png',
      categoryname: 'Seminarios',
      categoryviews: 74853
    },
    {
      id: 6,
      src: 'assets/img/icons/peliculas.png',
      categoryname: 'Peliculas',
      categoryviews: 74853
    },
    {
      id: 7,
      src: 'assets/img/icons/documentales.png',
      categoryname: 'Documentales',
      categoryviews: 74853
    },
    {
      id: 9,
      src: 'assets/img/icons/homeleticos.png',
      categoryname: 'Homeleticos',
      categoryviews: 74853
    },
    {
      id: 10,
      src: 'assets/img/icons/history.png',
      categoryname: 'Historicos',
      categoryviews: 74853
    },
  ];
  constructor() { }

  ngOnInit(): void {
  }

}
