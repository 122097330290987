import { Component, OnInit } from '@angular/core';
import { User } from '../../models/user';
import { AuthenticationService } from '../../services/authentication.service';

@Component({
  selector: 'app-videos',
  templateUrl: './videos.component.html',
  styleUrls: ['./videos.component.css']
})
export class VideosComponent implements OnInit {
  usuario: User;
  loggedIn: boolean;
  constructor(public authenticationService: AuthenticationService) { }

  ngOnInit(): void {
    this.usuario = this.authenticationService.currentUserValue;
    this.loggedIn = this.authenticationService.loggedIn;
    console.log(this.usuario);
    console.log(this.loggedIn);
  }
}
