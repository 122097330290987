import { Component, OnInit } from '@angular/core';
declare var WowzaPlayer: any;

@Component({
  selector: 'app-reproductor',
  templateUrl: './reproductor.component.html',
  styleUrls: ['./reproductor.component.css']
})
export class ReproductorComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
    WowzaPlayer.create('MediaPlayerMiniPlayer',
      {
        license: 'PLAY1-eKy3C-3khAP-vkavN-b87eF-z98xk',
        title: 'GDON Live Streaming',
        description: 'Tu Plataforma de streaming GDON',
        sourceURL: 'https://cloud2.streaminglivehd.com:1936/gdon/gdon/playlist.m3u8',
        autoPlay: true,
        volume: '75',
        mute: false,
        loop: false,
        audioOnly: false,
        uiShowQuickRewind: true,
        uiQuickRewindSeconds: '30',
        uiShowFullscreen: true
      }
    );
  }
}
