import {Injectable} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {environment} from '../../environments/environment';
import {Video} from '../models/video';

@Injectable({providedIn: 'root'})
export class VideosService {
  constructor(
    private http: HttpClient,
  ) {
  }

  public uploadVideo(payload: any): any {
    return this.http.post<any>(`${environment.apiUrl}/videos/upload`, payload);
  }

  public getVideo(videoId: string): any {
    return this.http.get<Video>(`${environment.apiUrl}/videos/${videoId}`);
  }

  public createComment(payload: any): any {
    return this.http.post(`${environment.apiUrl}/comments/create`, payload);
  }

  public getComments(videoId: string): any {
    return this.http.get<any>(`${environment.apiUrl}/comments/${videoId}`);
  }

  public likeVideo(payload: any): any {
    return this.http.post<any>(`${environment.apiUrl}/likes/likeVideo`, payload);
  }

  public undoLikeVideo(payload: any): any {
    return this.http.post<any>(`${environment.apiUrl}/likes/undoLikeVideo`, payload);
  }

  public dislikeVideo(payload: any): any {
    return this.http.post<any>(`${environment.apiUrl}/dislikes/dislikeVideo`, payload);
  }

  public undoDislikeVideo(payload: any): any {
    return this.http.post<any>(`${environment.apiUrl}/dislikes/undoDislikeVideo`, payload);
  }

  public likeComment(payload: any): any {
    return this.http.post<any>(`${environment.apiUrl}/likes/likeComment`, payload);
  }

  public undoLikeComment(payload: any): any {
    return this.http.post<any>(`${environment.apiUrl}/likes/undoLikeComment`, payload);
  }

  public dislikeComment(payload: any): any {
    return this.http.post<any>(`${environment.apiUrl}/dislikes/dislikeComment`, payload);
  }

  public undoDislikeComment(payload: any): any {
    return this.http.post<any>(`${environment.apiUrl}/dislikes/undoDislikeComment`, payload);
  }

  public userLikes(payload: any): any {
    return this.http.get<any>(`${environment.apiUrl}/likes/${payload.parentId}/${payload.username}`, {
      params: {
        token: payload.token
      }
    });
  }

  public userDislikes(payload: any): any {
    return this.http.get<any>(`${environment.apiUrl}/dislikes/${payload.parentId}/${payload.username}`, {
      params: {
        token: payload.token
      }
    });
  }

  public fetchByCategory(category: string): any {
    return this.http.get<any>(`${environment.apiUrl}/videos/category/${category}`);
  }

  public addView(payload: any): any {
    return this.http.post<any>(`${environment.apiUrl}/videos/addView`, payload);
  }
}
