<nav class="navbar navbar-expand navbar-light bg-white static-top osahan-nav sticky-top">
  <button class="btn btn-link btn-sm text-secondary order-1 order-sm-0" id="sidebarToggle">
    <i class="fas fa-bars"></i>
  </button> &nbsp;&nbsp;
  <a class="navbar-brand mr-1" href="index.html"><img class="img-fluid" alt="" src="assets/img/logo.png" width="90" style="margin-left:20px;"></a>

  <form class="d-none d-md-inline-block form-inline ml-auto mr-0 mr-md-5 my-2 my-md-0 osahan-navbar-search">
    <div class="input-group">
      <input type="text" class="form-control" placeholder="Busca lo que quieras ver">
      <div class="input-group-append">
        <button class="btn btn-light" type="button">
          <i class="fas fa-search"></i>
        </button>
      </div>
    </div>
  </form>

  <ul class="navbar-nav ml-auto ml-md-0 osahan-right-navbar">
    <li *ngIf="user && user.username !== ''" class="nav-item mx-1">
      <a class="nav-link" routerLink="/upload">
        <i class="fas fa-plus-circle fa-fw"></i>
        Subir Video
      </a>
    </li>
    <li class="nav-item dropdown no-arrow mx-1">
      <a class="nav-link dropdown-toggle" href="#" id="alertsDropdown" role="button" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
        <i class="fas fa-bell fa-fw"></i>
        <span class="badge badge-danger">9+</span>
      </a>
      <!--
      <div class="dropdown-menu dropdown-menu-right" aria-labelledby="alertsDropdown">
        <a class="dropdown-item" href="#"><i class="fas fa-fw fa-edit "></i> &nbsp; Action</a>
        <a class="dropdown-item" href="#"><i class="fas fa-fw fa-headphones-alt "></i> &nbsp; Another action</a>
        <div class="dropdown-divider"></div>
        <a class="dropdown-item" href="#"><i class="fas fa-fw fa-star "></i> &nbsp; Something else here</a>
      </div>
      -->
    </li>
    <li class="nav-item dropdown no-arrow mx-1">
      <a class="nav-link dropdown-toggle" href="#" id="messagesDropdown" role="button" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
        <i class="fas fa-envelope fa-fw"></i>
        <span class="badge badge-success">7</span>
      </a>
      <!--
      <div class="dropdown-menu dropdown-menu-right" aria-labelledby="messagesDropdown">
        <a class="dropdown-item" href="#"><i class="fas fa-fw fa-edit "></i> &nbsp; Action</a>
        <a class="dropdown-item" href="#"><i class="fas fa-fw fa-headphones-alt "></i> &nbsp; Another action</a>
        <div class="dropdown-divider"></div>
        <a class="dropdown-item" href="#"><i class="fas fa-fw fa-star "></i> &nbsp; Something else here</a>
      </div>
      -->
    </li>
    <li class="nav-item dropdown no-arrow osahan-right-navbar-user">
      <a class="nav-link dropdown-toggle user-dropdown-link" href="#" id="userDropdown" role="button" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
        <span style="font-weight:600;margin-right:15px;">{{ (user && user.username !== '') ? user.username : 'Invitado' }}</span>
        <img alt="Avatar" src="https://image.similarpng.com/very-thumbnail/2020/07/Icon-avatar-frame-hashtag-live-in-Instagram-vector-PNG.png">
      </a>
      <div *ngIf="user && user.username !== ''" class="dropdown-menu dropdown-menu-right" aria-labelledby="userDropdown">
        <a class="dropdown-item" href="account.html"><i class="fas fa-fw fa-user-circle"></i> &nbsp; Mi cuenta</a>
        <a class="dropdown-item" href="subscriptions.html"><i class="fas fa-fw fa-video"></i> &nbsp; Suscripciones</a>
        <a class="dropdown-item" href="settings.html"><i class="fas fa-fw fa-cog"></i> &nbsp; Configuraciones</a>
        <div class="dropdown-divider"></div>
        <a class="dropdown-item" href="#" data-toggle="modal" data-target="#logoutModal"><i class="fas fa-fw fa-sign-out-alt"></i> &nbsp; Logout</a>
      </div>
      <div *ngIf="!user" class="dropdown-menu dropdown-menu-right" aria-labelledby="userDropdown">
        <a class="dropdown-item" routerLink="/login"><i class="fas fa-fw fa-user-circle"></i> &nbsp; Login</a>
        <a class="dropdown-item" routerLink="/registro"><i class="fas fa-fw fa-video"></i> &nbsp; Registro</a>
      </div>
    </li>
  </ul>
</nav>
