import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';

import { ThemeOneComponent } from './pages/home/theme-one.component';
import { CanalesComponent } from './pages/canales/canales.component';
import { CanalComponent } from './pages/canal/canal.component';
import { VideosComponent } from './pages/videos/videos.component';
import { CategoriesComponent } from './pages/categories/categories.component';
import { CategoriaComponent } from './pages/categoria/categoria.component';
import { UploadComponent } from './pages/upload/upload.component';
import { UploadvideoComponent} from './pages/uploadvideo/uploadvideo.component';
import { HistoryComponent } from './pages/history/history.component';
import { LoginComponent } from './pages/login/login.component';
import { RegistroComponent } from './pages/registro/registro.component';
import { BlogComponent } from './pages/blog/blog.component';
import { ArticuloComponent } from './pages/articulo/articulo.component';
import { PlayComponent } from './pages/play/play.component';
import { Error404Component } from './pages/error/error404/error404.component';

const routes: Routes = [
  {path: '', component: ThemeOneComponent},
  {path: 'canales', component: CanalesComponent},
  {path: 'canal', component: CanalComponent},
  {path: 'video', component: VideosComponent},
  {path: 'categorias', component: CategoriesComponent},
  {path: 'categoria', component: CategoriaComponent},
  {path: 'upload', component: UploadComponent},
  {path: 'uploadvideo', component: UploadvideoComponent},
  {path: 'historial', component: HistoryComponent},
  {path: 'login', component: LoginComponent},
  {path: 'registro', component: RegistroComponent},
  {path: 'blog', component: BlogComponent},
  {path: 'articulo', component: ArticuloComponent},
  {path: 'watch/:video', component: PlayComponent},
  {path: '**', pathMatch: 'full', component: Error404Component }
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
