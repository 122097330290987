<div class="login-main-right bg-white p-5 mt-5 mb-5">
  <div class="owl-item cloned" style="width: 80%; margin:0 auto;">
    <owl-carousel-o [options]="customOptions">
      <ng-container *ngFor="let slide of slidesStore">
        <ng-template carouselSlide>
          <div class="item">
            <div class="carousel-login-card text-center">
              <img :src="{{slide.src}}" class="img-fluid" alt="{{slide.alt}}">
              <h5 class="mt-5 mb-3">{{slide.title}}</h5>
              <p class="mb-4">{{slide.subtitle.primer}}<br> {{slide.subtitle.segun}} <br> {{slide.subtitle.tercer}}</p>
            </div>
          </div>
        </ng-template>
      </ng-container>
    </owl-carousel-o>
  </div>
</div>
<!--
<div class="login-main-right bg-white p-5 mt-5 mb-5">
  <div class="owl-carousel owl-carousel-login owl-loaded owl-drag">
    <div class="owl-stage-outer">
      <div class="owl-stage" style="transform: translate3d(-520px, 0px, 0px); transition: all 1s ease 0s; width: 910px;">
        <div class="owl-item cloned" style="width: 130px;">
          <div class="item">
            <div class="carousel-login-card text-center">
              <img src="assets/img/login.png" class="img-fluid" alt="LOGO">
              <h5 class="mt-5 mb-3">Download videos effortlessly</h5>
              <p class="mb-4">when an unknown printer took a galley of type and scrambled<br> it to make a type specimen book. It has survived not <br>only five centuries</p>
            </div>
          </div>
        </div>
        <div class="owl-item cloned" style="width: 130px;">
          <div class="item">
            <div class="carousel-login-card text-center">
              <img src="assets/img/login.png" class="img-fluid" alt="LOGO">
              <h5 class="mt-5 mb-3">Create GIFs</h5>
              <p class="mb-4">when an unknown printer took a galley of type and scrambled<br> it to make a type specimen book. It has survived not <br>only five centuries</p>
            </div>
          </div>
        </div>
        <div class="owl-item" style="width: 130px;">
          <div class="item">
            <div class="carousel-login-card text-center">
              <img src="assets/img/login.png" class="img-fluid" alt="LOGO">
              <h5 class="mt-5 mb-3">​Watch videos offline</h5>
              <p class="mb-4">when an unknown printer took a galley of type and scrambled<br> it to make a type specimen book. It has survived not <br>only five centuries</p>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="owl-nav disabled">
      <button type="button" role="presentation" class="owl-prev">
        <span aria-label="Previous"></span>
      </button>
      <button type="button" role="presentation" class="owl-next">
        <span aria-label="Next"></span>
      </button>
    </div>
    <div class="owl-dots">
      <button role="button" class="owl-dot">
        <span></span>
      </button>
      <button role="button" class="owl-dot">
        <span></span>
      </button>
      <button role="button" class="owl-dot active">
        <span></span>
      </button>
    </div>
  </div>
</div>
-->
