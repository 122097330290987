import { Component, OnInit } from '@angular/core';
import {AuthenticationService} from '../../services/authentication.service';
import { ActivatedRoute, Router } from '@angular/router';
import { take } from 'rxjs/operators';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';

@Component({
  selector: 'app-registro',
  templateUrl: './registro.component.html',
  styleUrls: ['./registro.component.css']
})
export class RegistroComponent implements OnInit {
  registerForm: FormGroup;
  loading = false;
  submitted = false;
  returnUrl: string;
  size: 'compact';
  theme: 'light';
  type: 'image';
  lang: 'es';

  constructor(
    public authenticationService: AuthenticationService,
    private router: Router,
    private route: ActivatedRoute,
    private formBuilder: FormBuilder,
  ) {
    if (this.authenticationService.currentUserValue) {
      this.router.navigate(['/videos']);
    }
  }

  ngOnInit(): void {
    this.registerForm = this.formBuilder.group({
      username: ['', Validators.required],
      email: ['', [Validators.required, Validators.email]],
      password: ['', Validators.required],
      recaptcha: ['', Validators.required]
    });

    // get return url from route parameters or default to '/'
    this.returnUrl = this.route.snapshot.queryParams['returnUrl'] || '/login';
  }

  get f() { return this.registerForm.controls; }

  register(): void {
    this.submitted = true;
    console.log(this.f.username.value);
    console.log(this.f.password.value);
    console.log(this.f.recaptcha.value);
    console.log(this.f.email.value);
    if (this.registerForm.invalid) {
      console.log('es invalido');
      return;
    }
    this.loading = true;
    this.authenticationService.getIPAddress().subscribe((response: any) => {
      console.log(response.ip);
      const request = {
        email: this.f.email.value,
        username: this.f.username.value,
        password: this.f.password.value,
        role: 'user',
        lastIp: response.ip
      };
      this.authenticationService.register(request)
        .pipe(take(3))
        .subscribe(response => {
          console.log(response);
          this.router.navigate(['/login']);
        });
    });
  }

  handleSuccess(event): void {
    console.log(event);
  }

  handleLoad(): void {
    console.log( 'Esta cargando' );
  }

  handleExpire(): void {
    console.log('captcha ha expirado');
  }

  handleReset(): void {
    console.log('captcha ha sido reseteado');
  }

  siteKey(): any {
    return '6LdHqJ4UAAAAAM_qCsBbBmXYecwD2Vuo9klZIPEB';
  }
}
