import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment } from '../../environments/environment';
import { Video } from '../models/video';
import {map} from 'rxjs/operators';

@Injectable({providedIn: 'root'})
export class MyChannelService {
  constructor(
    private http: HttpClient,
  ) {}

  getvideos(email: string): any {
    return this.http.get<any>(`${environment.apiUrl}/videos/byuser/${email}`)
      .pipe( map(videos => {
        if (videos && videos.length > 0) {
          return videos;
        }
      }));
  }
}
