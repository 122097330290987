<body class="login-main-body">
<section class="login-main-wrapper">
  <div class="container-fluid pl-0 pr-0">
    <div class="row no-gutters">
      <div class="col-md-5 p-5 bg-white full-height">
        <div class="login-main-left">
          <div class="text-center mb-5 login-main-left-header pt-4">
            <img src="assets/img/favicon.png" class="img-fluid" alt="LOGO">
            <h5 class="mt-3 mb-3">Bienvenidos a GDON</h5>
            <p>Por favor rellena los datos <br> para crear tu cuenta y disfrutar de muchas ventajas.</p>
          </div>
          <form (submit)="register()" [formGroup]="registerForm">
            <div class="form-group">
              <label>Nombre de usuario</label>
              <input
                type="text"
                [ngClass]="{ 'is-invalid': submitted && f.username.errors }"
                name="username"
                formControlName="username"
                class="form-control"
                placeholder="Ingrese su nombre de usuario">
              <div *ngIf="submitted && f.username.errors" class="invalid-feedback">
                <div *ngIf="f.username.errors.required">Debe incluir un usuario</div>
              </div>
            </div>
            <div class="form-group">
              <label>Correo electrónico</label>
              <input
                type="email"
                [ngClass]="{ 'is-invalid': submitted && f.email.errors }"
                name="email"
                formControlName="email"
                class="form-control"
                placeholder="Ingrese su correo electrónico">
              <div *ngIf="submitted && f.email.errors" class="invalid-feedback">
                <div *ngIf="f.email.errors.required">Debe incluir un email</div>
              </div>
            </div>
            <div class="form-group">
              <label>Contraseña</label>
              <input
                type="password"
                [ngClass]="{ 'is-invalid': submitted && f.password.errors }"
                name="password"
                formControlName="password"
                class="form-control"
                placeholder="Ingrese su contraseña">
              <div *ngIf="submitted && f.password.errors" class="invalid-feedback">
                <div *ngIf="f.password.errors.required">Debe incluir un password</div>
              </div>
            </div>
            <div class="input-group mb-3">
              <ngx-recaptcha2 [siteKey]="siteKey()"
                              (reset)="handleReset()"
                              (expire)="handleExpire()"
                              (load)="handleLoad()"
                              (success)="handleSuccess($event)"
                              [useGlobalDomain]="false"
                              [size]="size"
                              [hl]="lang"
                              [theme]="theme"
                              [type]="type"
                              formControlName="recaptcha">
              </ngx-recaptcha2>
              <div *ngIf="submitted && f.recaptcha.errors" class="invalid-feedback">
                <div *ngIf="f.recaptcha.errors.required">No coincide las claves del captcha</div>
              </div>
            </div>
            <div class="mt-4">
              <button
                class="btn btn-outline-primary btn-block btn-lg"
                [disabled]="loading"
              >Registrarse</button>
            </div>
          </form>
          <div class="text-center mt-5">
            <p class="light-gray">Tienes una cuenta? <a routerLink="/login">Entra</a></p>
          </div>
        </div>
      </div>
      <div class="col-md-7">
        <app-login-carousel></app-login-carousel>
      </div>
    </div>
  </div>
</section>
