import { Component, OnInit } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { User } from '../../models/user';
import { AuthenticationService } from '../../services/authentication.service';

@Component({
  selector: 'app-upload',
  templateUrl: './upload.component.html',
  styleUrls: ['./upload.component.css']
})
export class UploadComponent implements OnInit {
  apikey: string;
  usuario: User;
  loggedIn: boolean;
  constructor(
    private route: ActivatedRoute,
    private router: Router,
    public authenticationService: AuthenticationService
  ) { }

  ngOnInit(): void {
    this.apikey = 'AVIrofLyzSDCF4AV4ADppz';
    this.usuario = this.authenticationService.currentUserValue;
    this.loggedIn = this.authenticationService.loggedIn;
    console.log(this.usuario);
    console.log(this.loggedIn);
  }

  onUploadSuccess(res: object): void {
    console.log('###uploadSuccess', res);
    // @ts-ignore
    if (res && res.filesFailed.length > 0) {
      console.log('Video fallido');
    } else {
      localStorage.setItem('video', JSON.stringify(res));
      this.router.navigate(['/uploadvideo']);
    }
  }

  onUploadError(err: any): void {
    console.log('###uploadError', err);
  }
}
