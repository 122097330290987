import {Component, OnInit} from '@angular/core';
import {ActivatedRoute} from '@angular/router';
import {VideosService} from '../../services/videos.service';
import {UtilsService} from '../../services/utils.service';
import {ChannelsService} from '../../services/channels.service';
import {Video} from '../../models/video';
import {Router} from '@angular/router';
import { User } from '../../models/user';
import { AuthenticationService } from '../../services/authentication.service';

@Component({
  selector: 'app-play',
  templateUrl: './play.component.html',
  styleUrls: ['./play.component.css']
})
export class PlayComponent implements OnInit {
  usuario: User;
  loggedIn: boolean;
  public videoId: string;
  public title: string;
  public description: string;
  public likes: number;
  public dislikes: number;
  public views: number;
  public tags: Array<string> = [];
  public createdAt: Date;
  public displayDate: string;
  public category: string;
  public channelName: string;
  public subscriptors: number;
  public comments: Array<any> = [];
  public comment = false;
  public newCommentText: string;
  public userLikesVideo: boolean;
  public userDislikesVideo: boolean;
  public videoUrl: string;
  public width = '90%';
  public subscribed = false;
  public notification = false;

  constructor(
    private activatedRoute: ActivatedRoute,
    private videosService: VideosService,
    private utilsService: UtilsService,
    private channelsService: ChannelsService,
    private route: Router,
    public authenticationService: AuthenticationService,
  ) {
  }

  ngOnInit(): void {
    this.usuario = this.authenticationService.currentUserValue;
    this.loggedIn = this.authenticationService.loggedIn;
    console.log(this.usuario);
    console.log(this.loggedIn);
    this.activatedRoute.paramMap.subscribe(params => {
      this.videoId = params.get('video');
      console.log(this.videoId);
      this.videoUrl = 'https://cdn.filestackcontent.com/' + this.videoId;
      this.videosService.getVideo(this.videoId)
        .subscribe(response => {
          console.log(response);
          this.setVideo(response);
          this.getComments();
          if (this.usuario !== null) {
            this.channelsService.checkSubscription({
              userEmail: this.usuario.email,
              channelName: this.channelName,
              token: this.usuario.token
            })
              .subscribe(result => {
                this.subscribed = result.subscribed;
                if (this.subscribed) {
                  this.notification = result.notification;
                }
              });
          }
        });
      if (this.usuario !== null) {
        this.videosService.userLikes({username: this.usuario.username, parentId: this.videoId, token: this.usuario.token})
          .subscribe(response => {
            this.userLikesVideo = response.userLikes;
          });
        this.videosService.userDislikes({username: this.usuario.username, parentId: this.videoId, token: this.usuario.token})
          .subscribe(response => {
            this.userDislikesVideo = response.userDislikes;
          });
      }
    });
  }

  public checkUser(): void {
    if (this.usuario === null) {
      this.redirect();
      return;
    }
    this.comment = true;
  }

  public setWidth(width: number): void {
    this.width = `${width}px`;
  }

  private setVideo(video: Video): void {
    const formattedDescription = JSON.stringify(video.description);
    this.title = video.title;
    this.description = JSON.parse(formattedDescription.replace(/\\n/g, `<br/>`));
    this.views = video.views;
    this.likes = video.likes;
    this.category = video.category;
    this.dislikes = video.dislikes;
    this.tags = [...JSON.parse(video.tags)];
    this.createdAt = new Date(video.createdAt);
    this.displayDate = this.generateDisplayDate();
    this.channelName = video.user.username;
    this.subscriptors = video.user.subscriptors;
  }

  private getComments(): void {
    this.videosService.getComments(this.videoId)
      .subscribe((response: any) => {
        response.forEach(comment => {
          comment.createdAt = this.utilsService.getDateDiff(new Date(comment.createdAt));
          const formattedText = JSON.stringify(comment.text);
          comment.text = JSON.parse(formattedText.replace(/\\n/g, `<br/>`));

          if (this.usuario !== null) {
            this.videosService.userLikes({username: this.usuario.username, parentId: comment.commentId, token: this.usuario.token})
              .subscribe((like: any) => {
                comment.userLikes = like.userLikes;
                this.videosService.userDislikes({
                  username: this.usuario.username,
                  parentId: comment.commentId,
                  token: this.usuario.token
                })
                  .subscribe((result: any) => {
                    comment.userDislikes = result.userDislikes;
                  });
                this.comments.push(comment);
              });
          } else {
            this.comments.push(comment);
          }
        });
      });
  }

  private generateDisplayDate(): string {
    return `${this.getDate()} ${this.getMonthName()} ${this.createdAt.getFullYear()}`;
  }

  public subscribe(): void {
    if (this.usuario === null) {
      this.redirect();
      return;
    }
    this.channelsService.subscribe({
      token: this.usuario.token,
      userEmail: this.usuario.email,
      notification: false,
      channelName: this.channelName,
      username: this.usuario.username
    })
      .subscribe(() => {
        this.subscribed = true;
        this.subscriptors++;
      });
  }

  public unsubscribe(): void {
    this.channelsService.unsubscribe({
      userEmail: this.usuario.email,
      channelName: this.channelName,
      token: this.usuario.token
    })
      .subscribe(() => {
        this.subscribed = false;
        this.subscriptors--;
      });
  }

  public updateNotification(): void {
    this.channelsService.updateNotification({
      userEmail: this.usuario.email,
      channelName: this.channelName,
      notification: !this.notification,
      token: this.usuario.token
    })
      .subscribe(() => {
        this.notification = !this.notification;
      });
  }

  public checkLikeStatus(action: string): void {
    if (this.usuario === null) {
      this.redirect();
      return;
    }

    if (action === 'like') {
      if (this.userDislikesVideo) {
        this.dislikeVideo();
      }
      this.likeVideo();
    } else {
      if (this.userLikesVideo) {
        this.likeVideo();
      }
      this.dislikeVideo();
    }
  }

  public checkCommentLikeStatus(payload: any, action: string): void {
    if (this.usuario === null) {
      this.redirect();
      return;
    }

    if (action === 'like') {
      if (payload.userDislikes) {
        this.dislikeComment(payload);
      }
      this.likeComment(payload);
    } else {
      if (payload.userLikes) {
        this.likeComment(payload);
      }
      this.dislikeComment(payload);
    }
  }

  public redirect(): void {
    this.route.navigate(['/registro']);
  }

  private likeVideo(): void {
    if (this.usuario === null) {
      return;
    }
    const payload = {
      token: this.usuario.token,
      userEmail: this.usuario.email,
      parentId: this.videoId,
      username: this.usuario.username
    };
    if (this.userLikesVideo) {
      this.videosService.undoLikeVideo(payload)
        .subscribe(() => {
          this.userLikesVideo = false;
          this.likes--;
        });
    } else {
      this.videosService.likeVideo(payload)
        .subscribe(() => {
          this.userLikesVideo = true;
          this.likes++;
        });
    }
  }

  private dislikeVideo(): void {
    if (this.usuario === null) {
      return;
    }
    const payload = {
      token: this.usuario.token,
      userEmail: this.usuario.email,
      parentId: this.videoId,
      username: this.usuario.username
    };
    if (this.userDislikesVideo) {
      this.videosService.undoDislikeVideo(payload)
        .subscribe(() => {
          this.userDislikesVideo = false;
          this.dislikes--;
        });
    } else {
      this.videosService.dislikeVideo(payload)
        .subscribe(() => {
          this.userDislikesVideo = true;
          this.dislikes++;
        });
    }
  }

  private likeComment(comment: any): void {
    if (this.usuario === null) {
      return;
    }
    const payload = {
      token: this.usuario.token,
      userEmail: this.usuario.email,
      parentId: comment.commentId,
      username: this.usuario.username
    };
    if (comment.userLikes) {
      this.videosService.undoLikeComment(payload)
        .subscribe(() => {
          comment.userLikes = false;
          comment.likes--;
        });
    } else {
      this.videosService.likeComment(payload)
        .subscribe(() => {
          comment.userLikes = true;
          comment.likes++;
        });
    }
  }

  private dislikeComment(comment: any): void {
    if (this.usuario === null) {
      return;
    }
    const payload = {
      token: this.usuario.token,
      userEmail: this.usuario.email,
      parentId: comment.commentId,
      username: this.usuario.username
    };
    if (comment.userDislikes) {
      this.videosService.undoDislikeComment(payload)
        .subscribe(() => {
          comment.userDislikes = false;
          comment.dislikes--;
        });
    } else {
      this.videosService.dislikeComment(payload)
        .subscribe(() => {
          comment.userDislikes = true;
          comment.dislikes++;
        });
    }
  }

  public createComment(): void {
    if (this.usuario === null) {
      return;
    }
    if (this.newCommentText === null || this.newCommentText === undefined) {
      return;
    }
    const commentId = this.videoId + Date.now();

    const payload = {
      text: this.newCommentText,
      commentId,
      hasReplys: false,
      isReply: false,
      mainCommentId: null,
      likes: 0,
      dislikes: 0,
      token: this.usuario.token,
      userEmail: this.usuario.email,
      videoId: this.videoId
    };

    this.videosService.createComment(payload)
      .subscribe(() => {
        const comment = {
          text: this.newCommentText,
          commentId,
          hasReplys: false,
          isReply: false,
          mainCommentId: null,
          likes: 0,
          dislikes: 0,
          user: {
            username: this.usuario.username
          },
          createdAt: this.utilsService.getDateDiff(new Date())
        };
        const formattedText = JSON.stringify(comment.text);
        comment.text = JSON.parse(formattedText.replace(/\\n/g, `<br/>`));
        this.comments.push(comment);
        this.newCommentText = '';
        this.comment = false;
      });
  }

  private getDate(): string {
    return this.createdAt.getDate() > 9 ? `${this.createdAt.getDate()}` : `0${this.createdAt.getDate()}`;
  }

  private getMonthName(): string {
    switch (this.createdAt.getMonth()) {
      case 0:
        return 'ene';
      case 1:
        return 'feb';
      case 2:
        return 'mar';
      case 3:
        return 'abr';
      case 4:
        return 'may';
      case 5:
        return 'jun';
      case 6:
        return 'jul';
      case 7:
        return 'ago';
      case 8:
        return 'sep';
      case 9:
        return 'oct';
      case 10:
        return 'nov';
      case 11:
        return 'dic';
    }
  }
}
