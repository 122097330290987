import {Component, Input, OnInit} from '@angular/core';
import { VideosService } from '../../services/videos.service';
import { AuthenticationService } from '../../services/authentication.service';
declare var WowzaPlayer: any;

@Component({
  selector: 'app-repro',
  templateUrl: './repro.component.html',
  styleUrls: ['./repro.component.css']
})
export class ReproComponent implements OnInit {

  @Input() videoId: string;
  private localUser: any;
  public videoUrl: string;
  public viewAdded = false;
  constructor(
    private videosService: VideosService,
    private auth: AuthenticationService
  ) { }

  ngOnInit(): void {
    this.localUser = JSON.parse(localStorage.getItem('currentUser'));
    this.videoUrl = 'https://cdn.filestackcontent.com/' + this.videoId;
  }

  setCurrentTime(data): void {
    let playTime = 0;
    let username;
    if (this.localUser === null) {
      this.auth.getIPAddress()
        .subscribe(response => {
          username = response.ip;
        });
    } else {
      username = this.localUser.username;
    }
    for (let i = 0 ; i < data.target.played.length; i++) {
      playTime += data.target.played.end(i) - data.target.played.start(i);
    }
    if (data.target.duration <= 31) {
      if (playTime >= (data.target.duration/2) && !this.viewAdded) {
        this.videosService.addView({videoId: this.videoId, username: username})
          .subscribe(() => {
            this.viewAdded = true;
          });
      }
    } else {
      if (playTime >= 30 && !this.viewAdded) {
        this.videosService.addView({videoId: this.videoId, username: username})
          .subscribe(() => {
            this.viewAdded = true;
          });
      }
    }
  }

  print(): void {
    console.log('VIDEOMODALCLICK');
  }
}
