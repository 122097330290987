import {Component, Input, OnInit} from '@angular/core';
import {AuthenticationService} from '../../services/authentication.service';
import { ChannelsService } from '../../services/channels.service';
import { Router } from '@angular/router';
import { User } from '../../models/user';

@Component({
  selector: 'app-sidebar',
  templateUrl: './sidebar.component.html',
  styleUrls: ['./sidebar.component.css']
})

export class SidebarComponent implements OnInit {

  @Input() loggedIn: boolean;
  @Input() user: User;
  public hasSubscriptions = false;
  public fullNavigationItems = [
    {
      class: this.route.url === '/canales' ? 'nav-item active' : 'nav-item',
      routerLink: '/canales',
      icon: 'fas fa-fw fa-users',
      name: 'Canales',
    },
    {
      class: this.route.url === '/canal' ? 'nav-item active' : 'nav-item',
      routerLink: '/canal',
      icon: 'fas fa-fw fa-user-alt',
      name: 'Mi canal',
    },
    {
      class: this.route.url === '/video' ? 'nav-item active' : 'nav-item',
      routerLink: '/video',
      icon: 'fas fa-fw fa-video',
      name: 'Videos',
    },
    {
      class: this.route.url === '/upload' ? 'nav-item active' : 'nav-item',
      routerLink: '/upload',
      icon: 'fas fa-fw fa-cloud-upload-alt',
      name: 'Cargar video',
    },
    {
      class: this.route.url === '/historial' ? 'nav-item active' : 'nav-item',
      routerLink: '/historial',
      icon: 'fas fa-fw fa-history',
      name: 'Historial',
    },
    {
      class: this.route.url === '/blog' ? 'nav-item active' : 'nav-item',
      routerLink: '/blog',
      icon: 'fas fa-fw fa-blog',
      name: 'Blog',
    },
    {
      class: this.route.url === '/categorias' ? 'nav-item active' : 'nav-item',
      routerLink: '/categorias',
      icon: 'fas fa-fw fa-list-alt',
      name: 'Categorias',
    }
  ];
  public navigationItems = [
    {
      class: this.route.url === '/' ? 'nav-item active' : 'nav-item',
      routerLink: '/',
      icon: 'fas fa-fw fa-home',
      name: 'Inicio',
    },
    /*
    {
      class: this.route.url === '/canales' ? 'nav-item active' : 'nav-item',
      routerLink: '/canales',
      icon: 'fas fa-fw fa-users',
      name: 'Canales',
    },
    {
      class: this.route.url === '/video' ? 'nav-item active' : 'nav-item',
      routerLink: '/video',
      icon: 'fas fa-fw fa-video',
      name: 'Videos',
    },
    {
      class: this.route.url === '/blog' ? 'nav-item active' : 'nav-item',
      routerLink: '/blog',
      icon: 'fas fa-fw fa-blog',
      name: 'Blog',
    },
    {
      class: this.route.url === '/categorias' ? 'nav-item active' : 'nav-item',
      routerLink: '/categorias',
      icon: 'fas fa-fw fa-list-alt',
      name: 'Categorias',
    }
     */
  ];
  public subscriptionItems = [];

  constructor(
    private auth: AuthenticationService,
    private channelService: ChannelsService,
    private route: Router
  ) {
    /*
    this.auth.currentUserValue.subscribe((user: User) => {
      const localUser = JSON.parse(localStorage.getItem('currentUser'));
      if (user === null && localUser !== null) {
        this.auth.setCurrentUser(localUser);
        this.loggedIn = true;
      } else {
        if (user !== null) {
          this.loggedIn = true;
        } else {
          this.loggedIn = false;
        }
      }
      if (this.loggedIn) {
        this.getSubscriptions(localUser);
      }
    });
     */
  }

  ngOnInit(): void {
  }

  private getSubscriptions(user: any): void {
    this.channelService.getSubscriptions({
      userEmail: user.email,
      limit: 4,
      token: user.token
    })
      .subscribe(response => {
        response.forEach(item => {
          this.subscriptionItems.push(item);
        });
        if (this.subscriptionItems.length > 0) {
          this.hasSubscriptions = true;
        }
      });
  }

}
